import React from 'react'
import Footer from '../components/common/Footer'
import Navbar from '../components/common/Navbar'

export default function GamePage() {
  return (
    <div>
      <Navbar />
      <div className="container mx-auto">
        <h1 className="text-3xl font-bold mt-8">Learn by playing exciting games.</h1>
        <p className="mb-4">Coming Soon....</p>
      </div>
      <Footer />
    </div>
  )
}
