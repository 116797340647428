import { motion } from "framer-motion";
import React from "react";
import Tilt from 'react-parallax-tilt';
import { Link } from "react-router-dom";
import ReactTypingEffect from 'react-typing-effect';
import HeroImg from "../../assets/images/hero.png";


function HeroSection({ theme }) {
  return (
    <section style={{ backgroundColor: theme.bgColor }} className="bg-[#d7fec8] rounded-br-[160px] dark:bg-gray-900">
      <div className="banner-tab banner-tab1 grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div className="banner-left-tab mr-auto place-self-center lg:col-span-7">
          <motion.h1
          style={{ color: theme.mainColor}}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.5 }} 
          className="max-w-2xl mb-4 text-8xl font-black text-[#0b996f] font-black tracking-tight leading-[3.5rem] md:text-5xl xl:text-6xl dark:text-white">
            Crazy
            <ReactTypingEffect text={["Typist."]} /><br />
          </motion.h1>
          <h2 style={{ color: theme.mainColor }} className="font-bold text-5xl text-[#00000] mt-10 md:text-5xl xl:text-5xl dark:text-white">
          Your Path to Typing Mastery
          </h2>
          <p  style={{ color: theme.themeName == "default" ? theme.subAltColor : theme.textColor }} className="max-w-2xl mb-6 mt-6 font-[400] text-[#474747] lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
            Unleash Your Typing Superpowers🚀 with Crazy Typing 🤯: Master the
            Keyboard, Boost Your Speed, and Conquer the Digital World!
          </p>
          <Link
            to="/practice"
            className="custom-btn-mob inline-flex bg-[#000] items-center justify-center px-5 py-3 text-base font-medium text-center text-white border border-gray-300 rounded-lg hover:bg-white hover:text-[#0b996f] hover:border-[#0b996f] focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
          >
            Start Typing
          </Link>
        </div>
        <div className="banner-left-tab lg:mt-0 lg:col-span-5 lg:flex">
          <Tilt>

          <motion.img
          initial={{ y: 1 }}
          animate={{ y: [-10, 10, -10] }}
          transition={{ duration: 2, repeat: Infinity }}
          drag
          dragConstraints={{
              top: -125,
              right: 125,
              bottom: 125,
              left: -125,
          }}
          dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
          dragElastic={0.5}
          whileTap={{ 
            scale: 0.8, 
            transition: { duration: 0.3 },
            cursor: "grabbing",
          }}
          whileDrag={{ scale: 1.1 }}
          src={HeroImg} alt="mockup" />
          </Tilt>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
