import React from "react";
import { DEFAULT_VOLUME_OPTIONS } from "../../constants/constants";

export default function SoundSettings({ theme, soundSettings, handleSoundSettingsChange, currentSoundSettings, setCurrentSoundSettings }) {
  const defaultVolumeOptions = DEFAULT_VOLUME_OPTIONS;


  const handleCurrentSoundSettingsChange = (newSetting) => {
    const newSoundSettings = {
      ...currentSoundSettings,
      ...newSetting,
    };

    setCurrentSoundSettings(newSoundSettings);
    handleSoundSettingsChange(newSoundSettings);
  }

  return (
    <div className="setting-content-outer w-[80%] border rounded-lg">
      <div className="setting-content block py-[30px] px-[50px]">
        <div className="flex flex-wrap items-center">
          <div className="block w-[50%]">
            <span
              style={{ color: theme.mainColor }}
              className="mb-3 font-light text-[18px] text-[#808080]"
            >
              Sound volume
            </span>
            <p
              style={{ color: theme.subColor }}
              className="max-w-xl mb-6 font-light text-[15px] text-black"
            >
              change the volume of the sound effects..
            </p>
          </div>
          <div className="st-btn flex flex-wrap w-[50%] justify-end">
            {
              defaultVolumeOptions.map((option) => {
                return (
                  <button
                    key={option}
                    onClick={() => handleCurrentSoundSettingsChange({ volume: option })}
                    style={{
                      backgroundColor: theme.mainColor,
                      color: theme.subAltColor,
                    }}
                    type="button"
                    className="w-[20%] text-white hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                    >
                    {option}
                  </button>
                )
              })
            }
          </div>
        </div>
        <div className="flex flex-wrap items-center">
          <div className="block w-[50%]">
            <span
              style={{ color: theme.mainColor }}
              className="mb-3 font-light text-[18px] text-[#808080]"
            >
              Play sound on error
            </span>
            <p
              style={{ color: theme.subColor }}
              className="max-w-xl mb-6 font-light text-[15px] text-black"
            >
              plays a short sound if you press an incorrect key or press space
              too early.{" "}
            </p>
          </div>
          <div className="st-btn flex flex-wrap w-[50%] justify-end">
            <button
              type="button"
              style={{
                backgroundColor: theme.mainColor,
                color: theme.subAltColor,
              }}
              onClick={() => handleCurrentSoundSettingsChange({ errorSound: true })}
              className="w-[25%] text-white hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            >
              On
            </button>
            <button
              type="button"
              style={{
                backgroundColor: theme.mainColor,
                color: theme.subAltColor,
              }}
              onClick={() => handleCurrentSoundSettingsChange({ errorSound: false })}
              className="w-[25%] text-white hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            >
              off
            </button>
          </div>
        </div>
        <div className="flex flex-wrap items-center">
          <div className="block w-[50%]">
            <span
              style={{ color: theme.mainColor }}
              className="mb-3 font-light text-[18px] text-[#808080]"
            >
              Play sound on typing
            </span>
            <p
              style={{ color: theme.subColor }}
              className="max-w-xl mb-6 font-light text-[15px] text-black"
            >
              plays a short sound if you type during practice.{" "}
            </p>
          </div>
          <div className="st-btn flex flex-wrap w-[50%] justify-end">
            <button
              type="button"
              style={{
                backgroundColor: theme.mainColor,
                color: theme.subAltColor,
              }}
              onClick={() => handleCurrentSoundSettingsChange({ typeSound: true })}
              className="w-[25%] text-white hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            >
              On
            </button>
            <button
              type="button"
              style={{
                backgroundColor: theme.mainColor,
                color: theme.subAltColor,
              }}
              onClick={() => handleCurrentSoundSettingsChange({ typeSound: false })}
              className="w-[25%] text-white hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            >
              off
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
