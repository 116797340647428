import React from "react";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import CertificateWhy from "../sections/CertificateSections/CertificateWhy";
import CertificateTestInfo from "../sections/CertificateTypingPageSections/CertificateTestInfo";
import TypingTestSection from "../sections/CertificateTypingPageSections/TypingTestSection";

export default function CertificateTypingPage({ theme }) {
  return (
    <>
      <Navbar />
      <TypingTestSection theme={theme}/>
      <CertificateTestInfo theme={theme}/>

      <CertificateWhy theme={theme}/>
      <Footer />
    </>
  );
}
