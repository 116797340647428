import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";


function ResetPasswordForm({theme}) {
  const { user, resetPassword, loading } = useContext(AuthContext);

  const { token, uid } = useParams();

  const handleResetFormSubmit = (e) => {
    e.preventDefault();
    const newPassword = e.target.newPassword.value;
    const confirmPassword = e.target.confirmPassword.value;

    // Include the token and uid parameters in the form data
    const formData = {
      newPassword,
      confirmPassword,
      token,
      uid,
    };
    // Call the forgotPassword function with the form data
    resetPassword(formData);
  };

  return (
    <form onSubmit={handleResetFormSubmit} style={{ color: theme.subAltColor }}>
      <div className="mb-6">
        <label
          htmlFor="password"
          className="block text-left mb-2 text-sm font-medium dark:text-white"
        >
          New Password
        </label>
        <input
          type="password"
          id="password"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          required
          placeholder="new password"
          name="newPassword"
        />
      </div>
      <div className="mb-6">
        <label
          htmlFor="password"
          className="block text-left mb-2 text-sm font-medium dark:text-white"
        >
          Confirm password
        </label>
        <input
          type="password"
          id="confirm-password"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          required
          placeholder="confirm password"
          name="confirmPassword"
        />
      </div>
      <div className="flex items-center justify-between">
        <button
          type="submit"
          disabled={loading}
          style={{ backgroundColor: theme.bgColor, color: theme.mainColor }}
          className={`bg-[#006a43] text-white px-4 py-2 rounded hover:bg-[#209b6ef2] ${loading ? `bg-[#209b6ef2] hover:cursor-not-allowed` : `` } focus:outline-none focus:bg-[#209b6ef2] hover:cursor-pointer`}        >
          Submit
        </button>
        
      </div>
    </form>
  );
}

export default ResetPasswordForm;
