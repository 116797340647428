import { jwtDecode } from "jwt-decode";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import serverURL from "../../serverConfig";
import { returnToast } from "../utils/helper";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwtDecode(localStorage.getItem("authTokens"))
      : null
  );
  const [loading, setLoading] = useState(true);
  // const loadingBarRef = useRef(null);
  const [settings, setSettings] = useState({});

  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    let isMounted = true;
    let url = `${serverURL}/api/accounts/general-settings/`;
  
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (isMounted) {
          setSettings(data[0]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    setLoading(false);
  }, []);

  const loginUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let url = `${serverURL}/api/accounts/token/`;

      const response = await fetch(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: e.target.email.value,
            password: e.target.password.value,
          }),
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        let decodedUser = jwtDecode(data.access);
        setUser(decodedUser);
        setAuthTokens(data);
        localStorage.setItem("authTokens", JSON.stringify(data));

        returnToast(`Hey ${decodedUser.username}! Welcome again`);

        navigate("/");
      } else if (response.status === 401) {
        returnToast(
          "Invalid credentials. or your account might be inactive/deleted."
        );
      } else if (response.status === 500) {
        returnToast("Something went wrong. Please try again.");
      }
    } catch (error) {
      returnToast(error.message, { className: "bg-red-500" });
    } finally {
      setLoading(false);
    }
  };

  const registerUser = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let url = `${serverURL}/api/accounts/register/`;

      const response = await fetch(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: e.target.username.value,
            email: e.target.email.value,
            password: e.target.password.value,
          }),
        }
      );
      const data = await response.json();
      if (response.status === 201) {
        let decodedUser = jwtDecode(data.tokens.access);
        setUser(decodedUser);
        setAuthTokens(data.tokens);
        localStorage.setItem("authTokens", JSON.stringify(data.tokens));

        returnToast(`Hey ${decodedUser.username}! Welcome to CrazyTypist`, {
          className: "",
        });

        navigate("/");
      } else if (response.status === 400) {
        setFormErrors(data);
        returnToast("Invalid data. Please check your data and try again.");
      } else if (response.status === 500) {
        returnToast("Something went wrong. Please try again.");
      }
    } catch (error) {
      returnToast(error.message, { className: "bg-red-500" });
    } finally {
      setLoading(false);
    }
  };

  const forgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let url = `${serverURL}/api/accounts/forgot-password/`;  
      
      const response = await fetch(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: e.target.email.value }),
        }
      );

      if (response.status === 200) {
        returnToast("Password reset email sent, Check your mail please.");

        navigate("/");
      } else {
        returnToast("Something went wrong. Please try again.");
      }
    } catch (error) {
      returnToast(error.message, { className: "bg-red-500" });
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (formData) => {
    setLoading(true);
    try {
      if (formData.newPassword !== formData.confirmPassword) {
        returnToast("Passwords do not match", { className: "bg-yellow-500" });
        return;
      }

      let url = `${serverURL}/api/accounts/reset-password/`;

      const response = await fetch(
        url, 
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            new_password: formData.newPassword,
            confirm_password: formData.confirmPassword,
            uid: formData.uid,
            token: formData.token,
          }),
        }
      );

      if (response.status === 200) {
        returnToast("Password reset successful. Please login to continue.");

        navigate("/login");
      } else {
        returnToast("Something went wrong. Please try again.");
      }
    } catch (error) {
      returnToast(error.message, { className: "bg-red-500" });
    } finally {
      setLoading(false);
    }
  };

  const logoutUser = () => {
    setTimeout(() => {
      setLoading(true);
      setAuthTokens(null);
      setUser(null);
      localStorage.removeItem("authTokens");
      setLoading(false);
      navigate("/login");
      returnToast("Logged out! Come again to have fun");
    }, 1000);
  };

  // useEffect(() => {
  //   if (loading) {
  //     loadingBarRef.current.continuousStart();
  //   } else {
  //     loadingBarRef.current.complete();
  //   }
  // }, [loading]);

  const deleteAccount = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let url = `${serverURL}/api/accounts/delete-user/${user.user_id}`;

      const response = await fetch(
        url,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 204) {
        returnToast("Account deleted successfully.");
        logoutUser();
      } else {
        returnToast("Something went wrong. Please try again.");
      }
    } catch (error) {
      returnToast(error.message, { className: "bg-red-500" });
    } finally {
      setLoading(false);
    }
  };

  const updateProfileImage = async (formData) => {
    setLoading(true);

    try {
      let url = `${serverURL}/api/profiles/update-profile-image/`;
      const response = await fetch(
        url,
        {
          method: "PATCH",
          headers: {
            authorization: "Bearer " + authTokens.access,
          },
          body: formData,
        }
      );

      if (response.status === 200) {

        returnToast("Profile Image updated successfully.");
      } else {
        returnToast("Something went wrong. Please try again.");
      }
    } catch (error) {
      returnToast(error.message, { className: "bg-red-500" });
    } finally {
      setLoading(false);
    }
  };

  // update user name
  const updateUsername = async (username) => {
    setLoading(true);
    
    try {
      let url = `${serverURL}/api/profiles/update-username/`;

      const response = await fetch(
        url,
        {
          method: "PATCH",
          headers: {
            'Content-Type': 'application/json',
            authorization: "Bearer " + authTokens.access,
          },
          body: JSON.stringify({ username: username }),
        }
      );

      if (response.status === 200) {
        returnToast("Username updated successfully.");
        return response.json();
      } else {
        returnToast("Something went wrong. Please try again.");
      }
    } catch (error) {
      returnToast(error.message, { className: "bg-red-500" });
    } finally {
      setLoading(false);
    }
  };

  const getUserProfile = async () => {
    setLoading(true);
    try {
      let url = `${serverURL}/api/profiles/get-user-profile/${user.user_id}`;

      const response = await fetch(
        url,
        {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            authorization: "Bearer " + authTokens.access,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        returnToast("Something went wrong. Please try again.");
      }
    } catch (error) {
      returnToast(error.message, { className: "bg-red-500" });
    } finally {
      setLoading(false)
    }
  }

  const contextData = {
    loading: loading,
    // loadingBarRef: loadingBarRef,
    user: user,
    loginUser: loginUser,
    logoutUser: logoutUser,
    registerUser: registerUser,
    forgotPassword: forgotPassword,
    resetPassword: resetPassword,
    setLoading: setLoading,
    settings: settings,
    deleteAccount: deleteAccount,
    authTokens: authTokens,
    updateProfileImage: updateProfileImage,
    updateUsername: updateUsername,
    getUserProfile: getUserProfile,
    formErrors: formErrors,
  };

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};
