import React from "react";
import { Link } from "react-router-dom";

function FinalCta({theme}) {
  return (
    <section style={{ backgroundColor: theme.bgColor }} className="bg-[#d7fec8] dark:bg-gray-900">
      <div className="max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div className="m-auto place-self-center lg:col-span-12">
          <h2 style={{ color: theme.mainColor }} className="text-[#0b996f] m-auto text-center max-w-2xl mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl xl:text-4xl dark:text-grey-800">
            Ready to get started?
          </h2>
          <p style={{ color: theme.subColor }} className="text-black m-auto text-center max-w-2xl mb-6 font-[400] lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
            Join over 1 Lakh+  people who have improved their typing skills
            with CrazyTypist.
          </p>
          <div className="text-center block">
            <Link
              to="/practice"
              className="custom-btn-mob font-bold m-auto w-[150px] bg-black text-white items-center border-2 border-gray-700 rounded-xl cursor-pointer flex justify-center max-w-full p-4 relative text-center transition duration-500 whitespace-nowrap no-underline"
            >
              Get Started
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FinalCta;
