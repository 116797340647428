import React from "react";

export default function CertificateInfo({ theme, certificates }) {
  const handleDownload = async (image, index) => {
    try {
      const response = await fetch(image);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = `certificate-${index + 1}.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Release the object URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download image", error);
    }
  };

  return (
    <div
      style={{ backgroundColor: theme.bgColor }}
      className="p-4 dark:bg-gray-800"
    >
      <section
        style={{ backgroundColor: theme.bgColor, color: theme.mainColor }}
        className="dark:bg-gray-900"
      >
        <div className="banner-tab banner-tab1 grid max-w-screen-xl px-2 py-2 mx-auto lg:gap-8 xl:gap-0 lg:py-10 w-full">
          <div className="text-center">
            <div className="flex flex-col">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <table id="certificates-table" className="min-w-full text-left text-sm font-light text-surface dark:text-white">
                      <thead className="border-b border-neutral-200 font-medium dark:border-white/10">
                        <tr>
                          <th scope="col" className="px-6 py-4 text-center">
                            #
                          </th>
                          <th scope="col" className="px-6 py-4 text-center">
                            Certificate Type
                          </th>
                          <th scope="col" className="px-6 py-4 text-center">
                            Typing Speed
                          </th>
                          <th scope="col" className="px-6 py-4 text-center">
                            Accuracy
                          </th>
                          <th scope="col" className="px-6 py-4 text-center">
                            Image
                          </th>
                          <th scope="col" className="px-6 py-4 text-center">
                            Download
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {certificates
                          ? certificates.map((certificate, index) => (
                              <tr
                                key={index}
                                className="border-b border-neutral-200 dark:border-white/10 text-center"
                              >
                                <td className="whitespace-nowrap px-6 py-4 font-medium">
                                  {index + 1}
                                </td>
                                <td className="whitespace-nowrap px-6 py-4 text-center">
                                  {certificate.certificate_type}
                                </td>
                                <td className="whitespace-nowrap px-6 py-4">
                                  {certificate.typing_speed}
                                </td>
                                <td className="whitespace-nowrap px-6 py-4">
                                  {certificate.accuracy}
                                </td>
                                <td className="whitespace-nowrap px-6 py-4">
                                  <img
                                    width={100}
                                    height={100}
                                    src={certificate.image}
                                    alt="certificate"
                                  />
                                </td>
                                <td className="whitespace-nowrap px-6 py-4">
                                  <button
                                    onClick={() =>
                                      handleDownload(certificate.image, index)
                                    }
                                    className="px-5 py-3 font-bold bg-green-500 text-black rounded-xl border-black border-[0.01rem] hover:bg-black hover:text-green-500 hover:border-green-500"
                                  >
                                    Download Now
                                  </button>
                                </td>
                              </tr>
                            ))
                          : "No certificates found"}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* <h4 className="certi-text max-w-5xl mb-4 text-[30px] text-[#0b996f] font-extrabold tracking-tight leading-none dark:text-white">
              English Layout
            </h4>

            <div className="p-4 result-outer get-result-outer">
              <div className="flex flex-wrap items-center text-center justify-center gap-[5px]">
                <div className="relative mb-[0] overflow-x-auto px-2 py-1 bg-[#ffff] rounded-[30px] custom-result">
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-[#7D7676] bg-[#fffff] dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-1 py-2">
                          <img
                            className="row-img"
                            src={certificateIcon}
                            alt="certificateIcn"
                          />{" "}
                          Certificate
                        </th>
                        <th scope="col" className="px-1 py-2">
                          <img
                            className="row-img"
                            src={deadlineIcon}
                            alt="deadlineIcn"
                          />{" "}
                          Speed
                        </th>
                        <th scope="col" className="px-1 py-2">
                          <img
                            className="row-img"
                            src={arrowIcon}
                            alt="arrowIcn"
                          />{" "}
                          Accuracy
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-[#ffff] dark:bg-gray-800 dark:border-gray-700">
                        <th
                          scope="row"
                          className="px-1 py-2 font-medium text-[#000] whitespace-nowrap dark:text-white"
                        >
                          <img
                            className="row-img"
                            src={premiumQualityIcn}
                            alt="premium-qualityIcn"
                          />{" "}
                          Gold
                        </th>
                        <th className="px-1 py-2 text-[#000]">70 wpm</th>
                        <th className="px-1 py-2 text-[#000]">99.5%</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="certificate-img mt-[30px] mb-[30px]">
              <img className="m-auto" src={goldIcon} alt="gold" />
            </div>
            <p className="text-black m-auto text-center max-w-2xl mb-6 font-[400] lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              Motivation quote
            </p>
            <div className="text-center flex downlaod-btn">
              <a
                href="#"
                className="custom-btn-mob font-bold m-auto w-[250px] bg-black text-white items-center border-2 border-gray-700 rounded-xl cursor-pointer flex justify-center max-w-full p-4 relative text-center transition duration-500 whitespace-nowrap no-underline"
              >
                Download Certificate
              </a>{" "}
              <a
                href="#"
                className="custom-btn-mob font-bold m-auto w-[250px] bg-black text-white items-center border-2 border-gray-700 rounded-xl cursor-pointer flex justify-center max-w-full p-4 relative text-center transition duration-500 whitespace-nowrap no-underline"
              >
                Improve my result
              </a>
            </div> */}
          </div>
        </div>
      </section>
      {/* <section style={{backgroundColor: theme.bgColor, color: theme.mainColor}} className=" dark:bg-gray-900 py-[30]">
        <div className="max-w-screen-xl mx-auto lg:gap-8 xl:gap-0 lg:grid-cols-12">
          <div className="m-auto place-self-center lg:col-span-12">
            <h2 className="certi-text text-[#0b996f] m-auto text-center max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-4xl xl:text-4xl dark:text-grey-800">
              If you want a new certificate, just take the test!
            </h2>
            <div className="text-center block">
              <a
                href="#"
                className="custom-btn-mob font-bold m-auto w-[200px] bg-black text-white items-center border-2 border-gray-700 rounded-xl cursor-pointer flex justify-center max-w-full p-4 relative text-center transition duration-500 whitespace-nowrap no-underline"
              >
                Take the test
              </a>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}
