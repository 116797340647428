import React, { useContext, useEffect, useState } from "react";
import userImage from "../../assets/images/user.png";
import AuthContext from "../../context/AuthContext";
import ImageModal from "./ImageModal";
import UserNameModal from "./UserNameModal";

export default function ProfileInfoAndSettings({theme}) {
  const { user, updateProfileImage, updateUsername, getUserProfile } = useContext(AuthContext);
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [isUsernameModalOpen, setUsernameModalOpen] = useState(false);
  const [newProfileImage, setNewProfileImage] = useState(null);
  const [newUserName, setNewUserName] = useState(user.username);
  const [userProfile, setUserProfile] = useState({});

  useEffect(() => {
    const fetchUserProfile = async () => {
      let data = await getUserProfile();
      if (!data) return;
      setUserProfile(data);
      setNewProfileImage(data.profile_image);
      setNewUserName(data.user.username);
    };

    fetchUserProfile();
  }, []);


  const handleOpenModal = (image, username) => {
    if (image) {
      setImageModalOpen(true);
    } else if (username) {
      setUsernameModalOpen(true);
    }
  };

  const handleCloseModal = (image, username) => {
    if (image) {
      setImageModalOpen(false);
    } else if (username) {
      setUsernameModalOpen(false);
    }
  };


  const handleFileChange = (event) => {
    setNewProfileImage(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    const formData = new FormData();
    formData.append('file', newProfileImage);

    await updateProfileImage(formData);
  };

  // update username
  const handleUsernameChange = async () => {
    let currentUsername = await updateUsername(newUserName);
    setNewUserName(currentUsername.username);
  }

  console.log(user);

  return (
    <div style={{ backgroundColor: theme.bgColor }} className="p-4 dark:bg-gray-800">
      <div className="w-[80%] user-main m-auto bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <div className="flex justify-end px-4 pt-4">

          <div
            id="dropdown"
            className="z-10 hidden text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
          >
            <ul className="py-2" aria-labelledby="dropdownButton">
              <li>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                >
                  Edit
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                >
                  Export Data
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                >
                  Delete
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col items-center pb-10">
          <img
            className="w-24 h-24 mb-3 rounded-full shadow-lg"
            src={
              newProfileImage instanceof Blob
                ? URL.createObjectURL(newProfileImage)
                : newProfileImage || userImage
            }
            alt="Bonnie image"
          />
          <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
            {newUserName ? newUserName : "username"}
          </h5>
          <span className="text-sm text-gray-500 dark:text-gray-400">
            Joined 24-12-2023
          </span>
          <div className="flex mt-4 space-x-3 md:mt-6">
            <button
              onClick={() => handleOpenModal(true, false)}
              style={{ backgroundColor: theme.bgColor, color: theme.mainColor }}
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white rounded-lg dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Change Profile Image
            </button>
          </div>
        </div>
      </div>
      <div className="w-[80%] p-[20px] user-main m-auto bg-white border border-gray-200 rounded-lg shadow flex flex-wrap items-center mt-[10px]">
        <div className="update-ac block w-[50%]">
          <span className="mb-3 font-light text-[20px] text-[#00000]">
            Update account name
          </span>
          <p className="max-w-xl mb-6 font-light text-[15px] text-[#808080] ">
            change the name of your account.you can only do this once every 30
            days.
          </p>
        </div>
        <div className="update-ac st-btn flex flex-wrap w-[50%] justify-end">
          <button
            type="button"
            onClick={() => handleOpenModal(false, true)}
            style={{ backgroundColor: theme.bgColor, color: theme.mainColor }}
            className="w-[35%] text-white hover:bg-gradient-to-br focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
          >
            Update username
          </button>
        </div>
      </div>

      <ImageModal theme={theme} isOpen={isImageModalOpen} onClose={handleCloseModal} handleFileUpload={handleFileUpload} setNewProfileImage={setNewProfileImage} handleFileChange={handleFileChange} />

      <UserNameModal setNewUserName={setNewUserName} handleUsernameChange={handleUsernameChange} theme={theme} isOpen={isUsernameModalOpen} onClose={handleCloseModal} />
    </div>
  );
}
