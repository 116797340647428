// Function to generate a random number between min and max (inclusive)
const randomIntFromRange = (min, max) => {
    const minNorm = Math.ceil(min);
    const maxNorm = Math.floor(max);
    const idx = Math.floor(Math.random() * (maxNorm - minNorm + 1) + minNorm);
    return idx;
};

// create a function which takes a array which have single 
// number and you have to return object key value

function createObjectFromNumber(numberArray) {
  var numberObject = {
      "key": numberArray[0],
      "value": numberArray[0]
  }
  return numberObject
}

function createObjectFromDate(dateString) {
  var dateObject = {
      "key": dateString,
      "value": dateString
  }
  return dateObject
}

// Function to generate a two or four-digit random number from single digit numbers
const generateRandomNumber = (numbersData) => {
  const numbers = Object.values(numbersData).map((number) =>
    parseInt(number.key)
  ); // Extract single digit numbers
  const randomIndex = randomIntFromRange(0, numbers.length - 1);

  const chosenNumber = numbers[randomIndex];
  const randomNumberLength =
    Math.random() < 0.5 ? 1 : Math.random() < 0.5 ? 2 : 4; // Randomly choose length

  if (randomNumberLength === 1) {
    return chosenNumber.toString(); // Return single digit
  } else if (randomNumberLength === 2) {
    return `${chosenNumber}${randomIntFromRange(0, 9)}`; // Return 2 digits
  } else {
    return `${randomIntFromRange(1900, 2100)}`; // Return 4 digits
  }
};

export { createObjectFromDate, createObjectFromNumber, generateRandomNumber, randomIntFromRange };

