import React from 'react'
import LoginForm from '../../components/LoginComponents/LoginForm'

function MainSection({ theme }) {
  return (
    <section style={{backgroundColor: theme.bgColor}} className="bg-[#d7fec8] dark:bg-gray-900">
    <div className="banner-tab banner-tab1 flex max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
		<div className="w-full py-8">
		<div  style={{backgroundColor: theme.subColor }} className="login-form m-auto bg-white p-8 rounded shadow-md w-[550px]">
			<h3  style={{color: theme.textColor}} className="text-2xl font-semibold mb-4">Login</h3>
			<LoginForm theme={theme}/>

		</div>
		</div>
	</div>
</section>
  )
}

export default MainSection
