import React, { useEffect, useState } from "react";
import TypingPerformanceChart from "./TypingPerformanceChart";

export default function ResultModal({
  testType,
  theme,
  showResultModal,
  setShowResultModal,
  result,
}) {

  const data = {
    wpm: result.typingSpeed.toFixed(2),
    accuracy: result.accuracy.toFixed,
    correctChars: result.correctCharacters,
    incorrectErrors: result.incorrectCharacters,
  };

  const [testTypeString, setTestTypeString] = useState("");

  const getTestTypeString = () => {
    let testString = ""
    if (testType?.words) {
      testString += `Words (${testType.wordLimit}), `;
    }
    if (testType?.quotes) {
      testString += `Quotes (${testType.wordLimit}), `;
    }
    if (testType?.time) {
      testString += `Time (${testType.timeLimit} seconds), `;
    }
    
    if (testType?.numbers) {
      testString += `numbers, `;
    }
    
    if(testType?.punctuation) {
      testString += `punctuation, `;

    }
    setTestTypeString(testString);
  }

  
  useEffect(() => {
    getTestTypeString();
  })

  return (
    <>
      {showResultModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative  my-6 mx-auto w-10/12">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <TypingPerformanceChart data={data} />
                  <div className="my-4 text-blueGray-500 text-sm leading-relaxed">
                    <table className="table-fixed w-11/12 mx-auto">
                      <thead>
                        <tr className=" flex justify-start w-full">
                          <th className="">Test Type</th>
                          <th>WPM</th>
                          <th className="">Accuracy</th>
                          <th>Correct chars</th>
                          <th>Incorrected Characters</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className=" w-full flex justify-start">
                          <td className="table-cell w-full">
                            {testTypeString}
                          </td>
                          <td className="table-cell w-full pl-[2rem]">{result.typingSpeed.toFixed(2)} wpm</td>
                          <td className="table-cell w-full pl-[2rem] ">{result.accuracy.toFixed(2)} %</td>
                          <td className="table-cell w-full text-center">{result.correctCharacters}</td>
                          <td className="table-cell w-full text-center">{result.incorrectCharacters}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/*footer*/}

                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <a
                    className="group relative text-white rounded-md bg-green-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1  ease-linear transition-all duration-150"
                    type="button"
                    href="/practice"
                  >
                   Restart
                  </a>
                  <button onClick={()=> setShowResultModal(false)} className="text-sm font-sans uppercase bg-red-500 font-[600] px-6 py-2 rounded-md text-white">Cancel</button>
                </div>
                <div className="text-center text-sm text-gray-500 m-5">
                You can also restart the test by pressing <strong>Ctrl+R</strong>.
              </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
