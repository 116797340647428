import { motion } from "framer-motion";
import React from "react";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";

export default function PrivacyPolicyPage({theme}) {
  return (
    <>
      <motion.main
        initial={{ width: 0, transition: { duration: 0.05 } }}
        animate={{ width: "100%" }}
        exit={{ width: window.innerWidth, transition: { duration: 0.05 } }}
        smooth={true}
      >
        <Navbar />
        <section style={{backgroundColor: theme.bgColor, color: theme.mainColor}} className="bg-[#d7fec8] dark:bg-gray-900">
          <div>
            <div className="container mx-auto py-8 px-4">
              <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
              <p className="mb-4">Effective date: September 8, 2021</p>
              <p className="mb-4">Last updated: Oct 24, 2023</p>
              <p className="mb-4">
                Thanks for trusting Crazy Typist ('Crazy Typist', 'we', 'us',
                'our') with your personal information! We take our
                responsibility to you very seriously and are committed to
                protecting your privacy.
              </p>

              <h2 className="text-2xl font-bold mt-8 mb-4">
                What information do we collect?
              </h2>
              <p className="mb-4">How do we collect your data?</p>
              <p className="mb-4">How will we use your data?</p>
              <p className="mb-4">How do we store your data?</p>
              <p className="mb-4">What are your data protection rights?</p>

              <h2 className="text-2xl font-bold mt-8 mb-4">
                What log data do we collect?
              </h2>
              <p className="mb-4">Advertisements</p>
              <p className="mb-4">Advertisements Part 2</p>

              <h2 className="text-2xl font-bold mt-8 mb-4">
                What are cookies?
              </h2>
              <p className="mb-4">How do we use cookies?</p>
              <p className="mb-4">What types of cookies do we use?</p>

              <h2 className="text-2xl font-bold mt-8 mb-4">
                Privacy policies of other websites
              </h2>
              <p className="mb-4">Changes to our privacy policy</p>

              <h2 className="text-2xl font-bold mt-8 mb-4">
                How to contact us
              </h2>
              <p className="mb-4">General inquiries: contact@crazytypist.com</p>
              <p className="mb-4">
                Advertising related inquiries:{" "}
                <a
                  href="mailto:crazytypist24@gmail.com"
                  className="text-blue-500"
                >
                  crazytypist24@gmail.com
                </a>
              </p>
              <p className="mb-4">
                Discord:{" "}
                <a target="_blank" href="https://discord.com/invite/ZAtC6cbV" className="text-blue-500">
                  https://discord.com/invite/ZAtC6cbV
                </a>
              </p>
            </div>
          </div>
        </section>
        <Footer />
      </motion.main>
    </>
  );
}
