import React, { useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import { returnToast } from '../../utils/helper';

export default function ContactForm({theme}) {
    const {loading, setLoading } = useContext(AuthContext);

    const handleContactSubmission = async (e) => {
        e.preventDefault();
        setLoading(true);
        const email = e.target.email.value;
        const message = e.target.message.value;
        const data = { email, message };

        let url = `${serverURL}/api/core/contact/`
      
        try {
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          });
      
          if (response.ok) {
            returnToast('Thanks for the feedback! We will get back to you soon.');
          } else {
            returnToast('Something went wrong. Please try again later.');
          }
        } catch (error) {
          console.error('Error:', error);
          returnToast('Something went wrong. Please try again later.');
        }
      
        setLoading(false);
      };
      
  return (
    <form onSubmit={handleContactSubmission}>
      <div className="mb-6">
        <label
          htmlFor="email"
          style={{ color: theme.subAltColor }}
          className="block mb-2 text-[#006a43] text-sm text-left font-medium dark:text-white"
        >
          Your email
        </label>
        <input
          type="email"
          id="email"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="example@gmail.com"
          name="email"
          required
        />
      </div>
      <div className="mb-6">
        <label
          htmlFor="email"
          style={{ color: theme.subAltColor }}

          className="block mb-2 text-[#006a43] text-sm text-left font-medium dark:text-white"
        >
          Feedback message
        </label>
        <textarea
          rows={5}
          id="message"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="type here..."
          name="message"
          required
        />
      </div>
      <div className="flex items-center justify-between">
        <button
          type="submit"
          disabled={loading}
          style={{ backgroundColor: theme.bgColor, color: theme.mainColor }}
          className={`bg-[#006a43] text-white px-4 py-2 rounded hover:bg-[#209b6ef2] ${loading ? `bg-[#209b6ef2] hover:cursor-not-allowed` : `` } focus:outline-none focus:bg-[#209b6ef2] hover:cursor-pointer`}
        >
          Submit
        </button>
        
      </div>
    </form>
  )
}
