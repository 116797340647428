import { motion } from "framer-motion";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import AwardSection from "../sections/HomeSections/AwardSection";
import CtaSection from "../sections/HomeSections/CtaSection";
import FeatureSection from "../sections/HomeSections/FeatureSection";
import FinalCta from "../sections/HomeSections/FinalCta";
import HeroSection from "../sections/HomeSections/HeroSection";
import ReviewSection from "../sections/HomeSections/ReviewSection";

import React from "react";


function HomePage({ theme }) {
  return (
    <>
      <motion.main
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        exit={{ width: window.innerWidth, transition: { duration: 0.05 } }}
        smooth={true}
      >
        <Navbar />
        <HeroSection theme={theme} />

        <FeatureSection theme={theme} />

        <CtaSection theme={theme} />

        <ReviewSection theme={theme} />

        <AwardSection theme={theme} />

        <FinalCta theme={theme} />

        <Footer />
      </motion.main>
    </>
  );
}

export default HomePage;
