
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import { Route, Routes, useLocation } from "react-router-dom";
import { useSoundSettings } from "./context/soundContext";
import { useTheme } from "./context/themeContext";
import AboutPage from "./pages/AboutPage";
import CertificatePage from "./pages/CertificatePage";
import CertificateResultPage from "./pages/CertificateResultPage";
import CertificateTypingPage from "./pages/CertificateTypingPage";
import ContactPage from "./pages/ContactPage";
import ForgetPassowordPage from "./pages/ForgetPasswordPage";
import GamePage from "./pages/GamePage";
import HomePage from "./pages/HomePage";
import LeaderBoardPage from "./pages/LeaderBoardPage";
import LoginPage from "./pages/LoginPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import ProfilePage from "./pages/ProfilePage";
import RegisterPage from "./pages/RegisterPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import SettingsPage from "./pages/SettingsPage";
import TermsPage from "./pages/TermsPage";
import TypingDashboard from "./pages/TypingDashboard";



const App = () => { 
  const { theme } = useTheme();
  const { soundSettings } = useSoundSettings();
  const [currentSoundSettings, setCurrentSoundSettings] = useState({
    errorSound: soundSettings?.errorSound || false,
    typeSound: soundSettings?.typeSound || false,
    volume: soundSettings?.volume || "medium"
  });
 
  const location = useLocation();

  return (
    // <ThemeProvider>
    <main className={`${theme ? theme.bgColor : ""}`}>
      <Toaster />
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<HomePage theme={theme}  />} />
          <Route path="about" element={<AboutPage theme={theme} />} />
          <Route path="login" element={<LoginPage theme={theme} />} />
          <Route path="forget-password" element={<ForgetPassowordPage theme={theme} />} />
          <Route
            path="reset-password/:uid/:token"
            element={<ResetPasswordPage theme={theme} />}
          />
          <Route path="signup" element={<RegisterPage theme={theme} />} />
          <Route path="practice" element={<TypingDashboard theme={theme} currentSoundSettings={currentSoundSettings} soundSettings={soundSettings} />} />
          <Route path="get-certificate" element={<CertificatePage theme={theme} />} />
          <Route path="certificate-test" element={<CertificateTypingPage theme={theme} />} />
          <Route path="certificate-result" element={<CertificateResultPage theme={theme} />} />
          <Route path="contact" element={<ContactPage theme={theme} />} />
          <Route path="privacy-policy" element={<PrivacyPolicyPage theme={theme} />} />
          <Route path="terms" element={<TermsPage theme={theme} />} />
          <Route path="profile" element={<ProfilePage theme={theme} />} />
          <Route path="leaderboard" element={<LeaderBoardPage theme={theme} />} />
          <Route path="games" element={<GamePage theme={theme} />} />
          <Route path="settings" element={<SettingsPage theme={theme} currentSoundSettings={currentSoundSettings} setCurrentSoundSettings={setCurrentSoundSettings}/>} />
          

        </Routes>
    </main>
    // </ThemeProvider>
  );
};

export default App;