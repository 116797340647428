import React from "react";
import arrowIcn from "../../assets/images/arrowIcn.png";
import bronzeCertificate from "../../assets/images/bronze-certificate.png";
import certificateIcn from "../../assets/images/certificateIcn.png";
import deadlineIcn from "../../assets/images/deadlineIcn.png";
import visibleBronzeCertificate from "../../assets/images/demo.png";
import goldIcon from "../../assets/images/demo2.png";
import greenCertificate from "../../assets/images/demo3.jpg";
import goldCertificate from "../../assets/images/gold-certificate.png";
import silverCertificate from "../../assets/images/silver-certificate.png";
import visibleSilverCertificate from "../../assets/images/silver-with-name.png";


export default function CertificateTestInfo({theme}) {
  return (
    <>
      <section style={{ backgroundColor: theme.bgColor }} className="bg-[#000] py-[40px]">
        <div className="container m-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
            <div className="p-4 result-outer">
              <div className="flex flex-wrap items-center text-center justify-center gap-[5px]">
                <div className="relative overflow-x-auto px-2 py-1 bg-[#D9D9D9] rounded-[30px] custom-result">
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-[#7D7676] bg-[#D9D9D9] dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-1 py-2">
                          <img
                            className="row-img"
                            src={certificateIcn}
                            alt="certificateIcn"
                          />{" "}
                          Certificate
                        </th>
                        <th scope="col" className="px-1 py-2">
                          <img
                            className="row-img"
                            src={deadlineIcn}
                            alt="deadlineIcn"
                          />{" "}
                          Speed
                        </th>
                        <th scope="col" className="px-1 py-2">
                          <img
                            className="row-img"
                            src={arrowIcn}
                            alt="arrowIcn"
                          />{" "}
                          Accuracy
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-[#D9D9D9] dark:bg-gray-800 dark:border-gray-700">
                        <th
                          scope="row"
                          className="px-1 py-2 font-medium text-[#000] whitespace-nowrap dark:text-white"
                        >
                          <img
                            className="row-img"
                            src={goldCertificate}
                            alt="premium-qualityIcn"
                          />{" "}
                          Gold
                        </th>
                        <th className="px-1 py-2 text-[#000]">70 wpm</th>
                        <th className="px-1 py-2 text-[#000]">99.99%</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <img src={goldIcon} alt="gold" />
              </div>
            </div>
            <div className="p-4 result-outer">
              <div className="flex flex-wrap items-center text-center justify-center gap-[5px]">
                <div className="relative overflow-x-auto px-2 py-1 bg-[#D9D9D9] rounded-[30px] custom-result">
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-[#7D7676] bg-[#D9D9D9] dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-1 py-2">
                          <img
                            className="row-img"
                            src={certificateIcn}
                            alt="certificateIcn"
                          />{" "}
                          Certificate
                        </th>
                        <th scope="col" className="px-1 py-2">
                          <img
                            className="row-img"
                            src={deadlineIcn}
                            alt="deadlineIcn"
                          />{" "}
                          Speed
                        </th>
                        <th scope="col" className="px-1 py-2">
                          <img
                            className="row-img"
                            src={arrowIcn}
                            alt="arrowIcn"
                          />{" "}
                          Accuracy
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-[#D9D9D9] dark:bg-gray-800 dark:border-gray-700">
                        <th
                          scope="row"
                          className="px-1 py-2 font-medium text-[#000] whitespace-nowrap dark:text-white"
                        >
                          <img
                            className="row-img"
                            src={silverCertificate}
                            alt="premium-qualityIcn"
                          />{" "}
                          Silver
                        </th>
                        <th className="px-1 py-2 text-[#000]">55 wpm</th>
                        <th className="px-1 py-2 text-[#000]">98.99%</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <img src={visibleSilverCertificate} alt="gold" />
              </div>
            </div>
            <div className="p-4 result-outer">
              <div className="flex flex-wrap items-center text-center justify-center gap-[5px]">
                <div className="relative overflow-x-auto px-2 py-1 bg-[#D9D9D9] rounded-[30px] custom-result">
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-[#7D7676] bg-[#D9D9D9] dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-1 py-2">
                          <img
                            className="row-img"
                            src={certificateIcn}
                            alt="certificateIcn"
                          />{" "}
                          Certificate
                        </th>
                        <th scope="col" className="px-1 py-2">
                          <img
                            className="row-img"
                            src={deadlineIcn}
                            alt="deadlineIcn"
                          />{" "}
                          Speed
                        </th>
                        <th scope="col" className="px-1 py-2">
                          <img
                            className="row-img"
                            src={arrowIcn}
                            alt="arrowIcn"
                          />{" "}
                          Accuracy
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-[#D9D9D9] dark:bg-gray-800 dark:border-gray-700">
                        <th
                          scope="row"
                          className="px-1 py-2 font-medium text-[#000] whitespace-nowrap dark:text-white"
                        >
                          <img
                            className="row-img"
                            src={bronzeCertificate}
                            alt="premium-qualityIcn"
                          />{" "}
                          Bronze
                        </th>
                        <th className="px-1 py-2 text-[#000]">45 wpm</th>
                        <th className="px-1 py-2 text-[#000]">97.99%</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <img src={visibleBronzeCertificate} alt="gold" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{backgroundColor: theme.bgColor}} className="dark:bg-gray-900">
        <div className="banner-tab banner-tab1 grid max-w-screen-xl px-4 py-4 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div  className="m-auto text-center place-self-center lg:col-span-12">
            <h2 style={{color: theme.mainColor }} className="max-w-5xl mb-4 text-3xl text-[#0b996f] font-extrabold tracking-tight leading-none md:text-4xl xl:text-5xl dark:text-white">
              Empowering Typists, One Certificate at a Time{" "}
            </h2>
            <p className="max-w-5xl mb-6 mt-6 font-[400] lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              At Crazy Every Test Taker Earns a Green Certificate, Because
              Starting Matters!
            </p>
            <div className="certificate-img">
              <img className="m-auto" src={greenCertificate} alt="gold" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
