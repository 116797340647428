import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import { CRAZY_TYPIST_THEMES, DEFAULT_THEME } from "../constants/constants";
import AuthContext from "../context/AuthContext";
import { useSoundSettings } from "../context/soundContext";
import { useTheme } from "../context/themeContext";
import AccountSettings from "../sections/SettingsPageSections/AccountSettings";
import SoundSettings from "../sections/SettingsPageSections/SoundSettings";
import ThemeSettings from "../sections/SettingsPageSections/ThemeSettings";


export default function SettingsPage({
  currentSoundSettings,
  setCurrentSoundSettings,
}) {
  const [tab, setTab] = useState("theme-settings");
  const { user } = useContext(AuthContext);
  let navigate = useNavigate();

  const handleSetTab = (tab) => {
    setTab(tab);
  };

  const { theme, autoSwitch, handleThemeChange, handleAutoSwitchThemeChange } =
    useTheme();
  const { soundSettings, handleSoundSettingsChange } = useSoundSettings();

  const setRandomTheme = () => {
    const randomTheme =
      CRAZY_TYPIST_THEMES[
        Math.floor(Math.random() * CRAZY_TYPIST_THEMES.length)
      ];
    handleThemeChange(randomTheme);
  };

  useEffect(() => {
    if (autoSwitch) {
      setRandomTheme();
    }
  }, [autoSwitch]);


  // reset sound settings
  const resetSoundSettings = () => {
    const defaultSoundSettings = {
      volume: "mute",
      errorSound: false,
      typeSound: false
    };

    setCurrentSoundSettings(defaultSoundSettings);
    handleSoundSettingsChange(defaultSoundSettings);
  };

  // reset theme settings
  const resetThemeSettings = () => {
    handleThemeChange(DEFAULT_THEME);

    //reload the page
    navigate("/")
  };

  return (
    <>
      <Navbar />

      <section
        style={{ backgroundColor: theme.bgColor }}
        className={`settings-outer bg-[#d7fec8] py-[50px]`}
      >
        <div className="container mx-auto px-[10px] settings">
          <div className="flex flex-wrap items-baseline h-screen text-white setting-inner">
            <div
              style={{ backgroundColor: theme.mainColor }}
              className="setting-sidebar w-[16%] mr-[4%] bg-[#006a43] rounded-lg shadow py-[10px]"
            >
              <ul className="text-center">
                { user && <li
                  className="mb-[0]"
                  onClick={() => handleSetTab("account-settings")}
                >
                  <a
                    href="#"
                    style={{ color: theme.themeName == "default" ? theme.subAltColor : theme.textColor }}
                    className="block w-[100%] p-[10px] text-[20px] font-600 hover:text-[#d7fec8] focus  :text-[#d7fec8]"
                  >
                    Account
                  </a>
                </li>}
                <li
                  className="mb-[0]"
                  onClick={() => handleSetTab("sound-settings")}
                >
                  <a
                    href="#"
                    style={{  color: theme.themeName == "default" ? theme.subAltColor : theme.textColor }}
                    className="block w-[100%] p-[10px] text-[20px] font-600 hover:text-[#d7fec8]"
                  >
                    Sound
                  </a>
                </li>
                <li
                  className="mb-[0]"
                  onClick={() => handleSetTab("theme-settings")}
                >
                  <a
                    href="#"
                    style={{ color: theme.themeName == "default" ? theme.subAltColor : theme.textColor }}
                    className="block w-[100%] p-[10px]  text-[20px] font-600 hover:text-[#d7fec8]"
                  >
                    Theme
                  </a>
                </li>
              </ul>
            </div>
            {user && (tab === "account-settings" && (
              <AccountSettings 
              theme={theme}
              handleAutoSwitchThemeChange={handleAutoSwitchThemeChange}
              resetSoundSettings={resetSoundSettings} 
              resetThemeSettings={resetThemeSettings}
              />
            )) ||
              (tab === "sound-settings" && (
                <SoundSettings
                  theme={theme}
                  soundSettings={soundSettings}
                  handleSoundSettingsChange={handleSoundSettingsChange}
                  currentSoundSettings={currentSoundSettings}
                  setCurrentSoundSettings={setCurrentSoundSettings}
                />
              )) ||
              (tab === "theme-settings" && (
                <ThemeSettings
                  theme={theme}
                  autoSwitchTheme={autoSwitch}
                  handleThemeChange={handleThemeChange}
                  handleAutoSwitchThemeChange={handleAutoSwitchThemeChange}
                  resetThemeSettings={resetThemeSettings}
                />
              ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
