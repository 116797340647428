import React, { useContext, useEffect, useState } from "react";
import CertificateInfo from "../components/ProfilePageComponents/CertificateInfo";
import ProfileInfoAndSettings from "../components/ProfilePageComponents/ProfileInfoAndSettings";
import TestsInfo from "../components/ProfilePageComponents/TestsInfo";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import TestAPIContext from "../context/TestContext";

export default function ProfilePage({theme}) {
  const [activeTab, setActiveTab] = useState("profile");
  const [certificates, setCertificates] = useState([]);

  const { getAllCertificates } = useContext(TestAPIContext);

  useEffect(() => {
    getAllCertificates().then((data) => {
      setCertificates(data.certificates);
    });
  }, []);

  return (
    <>
      <Navbar />
      <div className="border-b border-gray-200 dark:border-gray-700">
        <ul style={{backgroundColor: theme.bgColor, color: theme.mainColor}} className="flex flex-wrap justify-center  -mb-px text-sm font-medium text-center gap-[10%]">
          <li className="mr-2" onClick={() => {setActiveTab("profile")}}>
            <button
              className="inline-block p-4 border-b-2 rounded-t-lg"
              type="button"
            >
              Profile & Account Settings
            </button>
          </li>
          <li className="mr-2" onClick={() => setActiveTab("dashboard")}>
            <button
              className="inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              type="button"
            >
              Certificate
            </button>
          </li>
          <li className="mr-2" onClick={() => setActiveTab("tests")}>
            <button
              className="inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 "
              type="button"
            >
              Typing Performance Analytics
            </button>
          </li>
        </ul>
      </div>
      <div>
        {(activeTab === "profile" && <ProfileInfoAndSettings theme={theme} />) ||
          (activeTab === "dashboard" && <CertificateInfo theme={theme} certificates={certificates}/>) ||
          (activeTab === "tests" && <TestsInfo theme={theme} />)}
      </div>
      <Footer />
    </>
  );
}
