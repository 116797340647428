
import EnglishMostFrequentWords from '../assets/vocab/englishWordMostCommon.json';
import NumberMostFrequent from '../assets/vocab/numbers.json';
import PuntuationMostFrequent from '../assets/vocab/puntuations.json';
import quotesMostFrequent from '../assets/vocab/quotes.json';

const COMMON_WORDS = EnglishMostFrequentWords;
const NUMBERS = NumberMostFrequent;
const PUNCTUATIONS = PuntuationMostFrequent;
const QUOTES = quotesMostFrequent;
const DEFAULT_DIFFICULTY  = "normal"
const DEFAULT_WORDS_COUNT = 200
const ENGLISH_MODE = "english"
const DEFAULT_TIME = 10
const DEFAULT_TIME_15 = 15
const DEFAULT_TIME_30 = 30
const DEFAULT_TIME_60 = 60
const DEFAULT_WORD_10 = 10
const DEFAULT_WORD_20 = 20
const DEFAULT_WORD_50 = 50
const DEFAULT_WORD_100 = 100
const DEFAULT_WORD_200 = 200
const MAX_WORD_LIMIT = 400

const DEFAULT_VOLUME_OPTIONS = [
    "mute",
    "low",
    "medium",
    "high"
]

const DEFAULT_VOLUME_OPTIONS_VALUES = {
    "mute": 0,
    "low": 0.3,
    "medium": 0.6,
    "high": 1
}

const DEFAULT_SOUND_SETTINGS = {
    volume: "medium",
    errorSound: true,
    typeSound: true
}

const DEFAULT_THEME = {
    themeName: "default",
    bgColor: "#d7fec8",
    mainColor: "#0b996f",
    caretColor: "#ff3a32",
    subColor: "#000000",
    subAltColor: "#0e0506",
    textColor: "#ffffff",
    errorColor: "#771b1f",
    errorExtraColor: "#591317",
    colorfulErrorColor: "#771b1f",
    colorfulErrorExtraColor: "#591317",
}

const CRAZY_TYPIST_THEMES = [
    {
      themeName: "alien",
      bgColor: "#000",
      mainColor: "#15ff00",
      caretColor: "#15ff00",
      subColor: "#006500",
      subAltColor: "#032000",
      textColor: "#d1ffcd",
      errorColor: "#da3333",
      errorExtraColor: "#791717",
      colorfulErrorColor: "#da3333",
      colorfulErrorExtraColor: "#791717",
    },
    {
      themeName: "calm",
      bgColor: "#05385b",
      hoverColor: "#ffffff",
      mainColor: "#5cdb95",
      caretColor: "#5cdb95",
      subColor: "#20688a",
      subAltColor: "#07324e",
      textColor: "#edf5e1",
      errorColor: "#f35588",
      errorExtraColor: "#a3385a",
      colorfulErrorColor: "#f35588",
      colorfulErrorExtraColor: "#a3385a",
    },
    {
      themeName: "fire",
      bgColor: "#1a0b0c",
      mainColor: "#ff3a32",
      caretColor: "#ff3a32",
      subColor: "#e2a528",
      subAltColor: "#0e0506",
      textColor: "#4a4d4e",
      errorColor: "#771b1f",
      errorExtraColor: "#591317",
      colorfulErrorColor: "#771b1f",
      colorfulErrorExtraColor: "#591317",
    },
    {
      themeName: "ocean breeze",
      bgColor: "#055f67",
      mainColor: "#0ca4a5",
      caretColor: "#0ca4a5",
      subColor: "#378fa6",
      subAltColor: "#01343f",
      textColor: "#f5f5f5",
      errorColor: "#d62828",
      errorExtraColor: "#9d0208",
      colorfulErrorColor: "#d62828",
      colorfulErrorExtraColor: "#9d0208",
    },
    {
      themeName: "enchanted forest",
      bgColor: "#1d3c34",
      mainColor: "#5f8250",
      caretColor: "#5f8250",
      subColor: "#7a9f76",
      subAltColor: "#0e1e18",
      textColor: "#f2ede9",
      errorColor: "#a03e3e",
      errorExtraColor: "#5c1414",
      colorfulErrorColor: "#a03e3e",
      colorfulErrorExtraColor: "#5c1414",
    },
    {
      themeName: "sunset serenity",
      bgColor: "#3a1f30",
      mainColor: "#b56576",
      caretColor: "#b56576",
      subColor: "#d9a7c7",
      subAltColor: "#271018",
      textColor: "#f5f0e1",
      errorColor: "#8a4f5a",
      errorExtraColor: "#5c2c35",
      colorfulErrorColor: "#8a4f5a",
      colorfulErrorExtraColor: "#5c2c35",
    }
  ];
export {
  COMMON_WORDS, CRAZY_TYPIST_THEMES, DEFAULT_DIFFICULTY,
  DEFAULT_SOUND_SETTINGS, DEFAULT_THEME, DEFAULT_TIME, DEFAULT_TIME_15,
  DEFAULT_TIME_30, DEFAULT_TIME_60, DEFAULT_VOLUME_OPTIONS,
  DEFAULT_VOLUME_OPTIONS_VALUES, DEFAULT_WORDS_COUNT,
  DEFAULT_WORD_10, DEFAULT_WORD_100, DEFAULT_WORD_20,
  DEFAULT_WORD_200, DEFAULT_WORD_50, ENGLISH_MODE, MAX_WORD_LIMIT, NUMBERS,
  PUNCTUATIONS, QUOTES
};

