import React from "react";
import AccountDeleteConfirmModal from "../../components/AccountSettingsComponents/AccountDeleteConfirmModal";
import AuthContext from "../../context/AuthContext";

export default function AccountSettings({ theme, handleAutoSwitchThemeChange, resetSoundSettings, resetThemeSettings }) {
  const [openConfirmDeleteAccountModal, setOpenConfirmDeleteAccountModal] =
    React.useState(false);

  const { deleteAccount } = React.useContext(AuthContext);

  const handleDeleteAccount = (e) => {
    deleteAccount(e);
    setOpenConfirmDeleteAccountModal(false);
  }

  const resetSettings = () => {
    // reset auto switch theme
    handleAutoSwitchThemeChange(false);

    // reset sound settings
    resetSoundSettings();

    // reset theme settings
    resetThemeSettings();
  }

  return (
    <div className="setting-content-outer w-[80%] border rounded-lg">
      <div className="setting-content block py-[30px] px-[50px]">
        {/* <div className="flex flex-wrap items-center">
          <div className="block w-[50%]">
            <span style={{color: theme.mainColor}} className="mb-3 font-light text-[18px] text-[#808080]">
              Ads
            </span>
            <p style={{color: theme.subColor}} className="max-w-xl mb-6 font-light text-[15px] text-black">
              You can disable or enable ads at any time. "Result" will show one
              ad on the result page, "on" will add floating vertical banners,
              and "sellout" will add multiple ads on every page.
            </p>
          </div>
          <div className="st-btn flex flex-wrap w-[50%] justify-end">
            <button
              type="button"
              className="w-[24%] text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            >
              results
            </button>

            <button
              type="button"
              className="w-[24%] text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            >
              Sell out
            </button>
          </div>
        </div> */}
        <div className="flex flex-wrap items-center">
          <div className="block w-[50%]">
            <span
              style={{ color: theme.mainColor }}
              className="mb-3 font-light text-[18px] text-[#808080]"
            >
              Reset settings
            </span>
            <p
              style={{ color: theme.subColor }}
              className="max-w-xl mb-6 font-light text-[15px] text-black"
            >
              Resets settings to the default <br />
              <span className="text-red-500 text-xs">
                You can't undo this action
              </span>
            </p>
          </div>
          <div className="st-btn flex flex-wrap w-[50%] justify-end">
            <button
              onClick={resetSettings}
              style={{
                backgroundColor: theme.mainColor,
                color: theme.subAltColor,
              }}
              type="button"
              className="w-[50%] text-white hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            >
              reset settings
            </button>
          </div>
        </div>

        <div className="flex flex-wrap items-center">
          <div className="block w-[50%]">
            <span
              style={{ color: theme.mainColor }}
              className="mb-3 font-light text-[18px] text-[#808080]"
            >
              Delete account
            </span>
            <p
              style={{ color: theme.subColor }}
              className="max-w-xl mb-6 font-light text-[15px] text-black"
            >
              Deletes your account and all data connected to it. <br />
              <span className="text-red-500 text-xs">
                You can't undo this action
              </span>
            </p>
          </div>
          <div className="st-btn flex flex-wrap w-[50%] justify-end">
            <button
              type="button"
              style={{
                backgroundColor: theme.mainColor,
                color: theme.subAltColor,
              }}
              onClick={() => setOpenConfirmDeleteAccountModal(true)}
              className="w-[50%] text-white hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            >
              delete account
            </button>
          </div>
        </div>
      </div>
      <AccountDeleteConfirmModal isOpen={openConfirmDeleteAccountModal} onClose={setOpenConfirmDeleteAccountModal} theme={theme} handleDeleteAccount={handleDeleteAccount} />
    </div>
  );
}
