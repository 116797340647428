import React, { useContext, useEffect, useState } from "react";
import TestAPIContext from "../../context/TestContext";

export default function TestsInfo({theme}) {
  const {getTypingResultAPI} = useContext(TestAPIContext);
  const [testResult, setTestResult] = useState({});

  useEffect(() => {
    getTypingResultAPI().then(result => {
      setTestResult(result);
    });
  }, []);  // Empty dependency array means this effect runs once on mount

  return (
    <div style={{backgroundColor: theme.bgColor}} className="p-4 dark:bg-gray-800">
      <section style={{backgroundColor: theme.bgColor}} className="dark:bg-gray-900">
        <div className="banner-tab banner-tab1 grid max-w-screen-xl px-2 py-2 mx-auto lg:gap-8 xl:gap-0 lg:py-10 lg:grid-cols-12">
          <div className="m-auto text-center place-self-center lg:col-span-12">
            <div className="p-4 result-outer get-result-outer lg:w-[125%]">
              <div className="flex flex-wrap items-center text-center justify-center gap-[5px]">
                <div className="graph relative mb-[0] overflow-x-auto px-2 py-1 bg-[#ffff] rounded-[30px] custom-result">
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-[#7D7676] bg-[#ffff] dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-1 py-2">
                          tests completed
                        </th>
                        <th scope="col" className="px-1 py-2">
                          timing typing
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-[#ffff] dark:bg-gray-800 dark:border-gray-700">
                        <th className="px-1 py-2 text-[#000]">5</th>
                        <th className="px-1 py-2 text-[#000]">00:01:07</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="certificate-img mt-[30px] mb-[30px]">
              {/* <img className="m-auto" src="images/graph.png" alt="gold" /> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
