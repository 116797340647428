import { useEffect, useState } from "react";

export const useTypingTest = (initialString, inputString ) => {
  // Initialize state variables
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [typingSpeed, setTypingSpeed] = useState(0);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [accuracy, setAccuracy] = useState(0);

  function countIncorrectCharacters(word1, word2) {
    if (!word2) {
      return 0;
    }

    let incorrectCharacters = 0;
    
    // Compare characters within the words
    for (let i = 0; i < Math.min(word1.length, word2.length); i++) {
      if (word1[i] !== word2[i]) {
        incorrectCharacters += 1;
      }
    }
  
    return incorrectCharacters;
  }

  // Split the initial and input strings into words
  const words1 = initialString.split(" ");
  const words2 = inputString.split(" ");

  // Initialize variables to track the typing test
  const differences = [];
  let correctWords = 0;
  let correctCharacters = 0;
  let totalCharacters = 0;
  let incorrectCharacters = 0;

  // Compare the words in the initial and input strings
  for (let i = 0; i < words1.length; i++) {
    words1[i] = words1[i]?.replace(/\s/g, "");
    words2[i] = words2[i]?.replace(/\s/g, "");

    if (words1[i] === words2[i]) {
      correctWords++;
      correctCharacters += words1[i].length;
    } else {
      differences.push(words1[i]);
    }
    totalCharacters += words1[i].length;
  }

  // Update the time elapsed, typing speed, and accuracy when necessary
  useEffect(() => {
    if (startTime && endTime) {
      const elapsed = (endTime - startTime) / 1000; // in seconds
      setTimeElapsed(elapsed);
      const speed = correctWords / (elapsed / 60); // words per minute
      setTypingSpeed(speed);
      const acc = correctCharacters / totalCharacters * 100; // in percentage
      setAccuracy(acc);
    }
  }, [startTime, endTime, correctWords, totalCharacters]);

  // Handle changes to the input string
  const handleInputChange = () => {
    if (!startTime) {
      setStartTime(Date.now());
    }   
    setEndTime(Date.now());
  }

  // Compare the words in the initial and input strings
  for (let i = 0; i < Math.min(words1.length, words2.length); i++) {
    words1[i] = words1[i]?.replace(/\s/g, "");
    words2[i] = words2[i]?.replace(/\s/g, "");

    if (words1[i] !== words2[i]) {
      incorrectCharacters += countIncorrectCharacters(words1[i], words2[i]);
    }
  }
  // Prepare the results of the typing test
  const testResult = {
      differences, 
      correctCharacters, 
      incorrectCharacters,
      accuracy,
      typingSpeed,
      timeElapsed
  };

  // Return the results of the typing test
  return {
    testResult,
    handleInputChange, 
  };
}