import React from "react";

function LeaderBoardPreferences({
  setAllTime,
  setToday,
  setTime,
  time,
  allTime,
  today,
  theme,
}) {
  return (
    <div
      style={{ backgroundColor: theme.bgColor }}
      className="flex justify-between items-center p-5"
    >
      <h1
        style={{
          color:
            theme.themeName == "default" ? theme.subAltColor : theme.textColor,
        }}
        className="text-left font-[600] text-[2rem]"
      >
        Leader Board
      </h1>
      <div className="flex items-center space-x-4">
        <div
          style={{
            color:
              theme.themeName === "default"
                ? theme.subAltColor
                : theme.textColor,
          }}
        >
          <label htmlFor="time">Time</label>
          <select
            name="time"
            id="time"
            className="ml-2 rounded"
            value={time}
            onChange={(e) => setTime(parseInt(e.target.value))}
            style={{ backgroundColor: theme.bgColor,  color: theme.themeName == "default" ? theme.subAltColor : theme.textColor }}
          >
            <option value="10" style={{ color: theme.themeName == "default" ? theme.subAltColor : theme.textColor }}>
              10
            </option>
            <option value="15" style={{ color: theme.themeName == "default" ? theme.subAltColor : theme.textColor }}>
              15
            </option>
            <option value="30" style={{ color: theme.themeName == "default" ? theme.subAltColor : theme.textColor }}>
              30
            </option>
            <option value="60" style={{ color: theme.themeName == "default" ? theme.subAltColor : theme.textColor }}>
              60
            </option>
          </select>
        </div>
        <div>
          <button
            onClick={() => {
              setAllTime(true);
              setToday(false);
            }}
            style={{
              backgroundColor: theme.bgColor,
              color: theme.mainColor,
              borderColor: allTime ? theme.subColor : "transparent",
              borderWidth: allTime ? "2px" : "1px",
              borderStyle: "solid",
            }}
            className={`px-4 py-2 rounded-md`}
          >
            All Time
          </button>
          <button
            onClick={() => {
              setAllTime(false);
              setToday(true);
            }}
            style={{
              backgroundColor: theme.bgColor,
              color: theme.mainColor,
              borderColor: today ? theme.subColor : "transparent",
              borderWidth: today ? "2px" : "1px",
              borderStyle: "solid",
            }}
            className={`px-4 py-2 rounded-md`}
          >
            Today
          </button>
        </div>
      </div>
    </div>
  );
}

export default LeaderBoardPreferences;
