import React from "react";
import AuthContext from "../../context/AuthContext";


function ForgetPasswordForm({theme}) {
  const { user, forgotPassword, loading} = React.useContext(AuthContext);

  return (
    <form onSubmit={forgotPassword} style={{color: theme.bgColor}}>
      <div className="mb-6">
        <label
          htmlFor="email"
          className="block mb-2 text-sm text-left font-medium dark:text-white"
        >
          Your email
        </label>
        <input
          type="email"
          id="email"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="example@gmail.com"
          name="email"
          required
        />
      </div>
      <div className="flex items-center justify-between">
        <button
          type="submit"
          disabled={loading}
          style={{ backgroundColor: theme.bgColor, color: theme.mainColor }}
          className={`bg-[#006a43] text-white px-4 py-2 rounded hover:bg-[#209b6ef2] ${loading ? `bg-[#209b6ef2] hover:cursor-not-allowed` : `` } focus:outline-none focus:bg-[#209b6ef2] hover:cursor-pointer`}
        >
          Submit
        </button>
        
      </div>
    </form>
  );
}

export default ForgetPasswordForm;
