import React from "react";

export default function CertificateWhy({theme}) {
  return (
    <section style={{backgroundColor: theme.bgColor}} className="bg-[#d7fec8] dark:bg-gray-900">
      <div className="max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div className="m-auto place-self-center lg:col-span-12">
          <h2 style={{color: theme.mainColor}} className="text-[#0b996f] m-auto text-center max-w-4xl mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-2xl xl:text-3xl dark:text-grey-800">
            Why do I need to take typing test
          </h2>
          <p style={{color: theme.themeName == "default" ? theme.subAltColor : theme.textColor}}  className="text-black m-auto text-center max-w-4xl mb-6 font-[400] lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
            There are many reasons why you might want to take a typing speed
            test. Perhaps you’re curious to find out how fast you can type, or
            maybe you want to see if you need to improve your accuracy. Either
            way, a typing speed test is a great way to estimate your progress.
            The average typing speed is 40 words per minute, so if you can beat
            that, you’re doing great! You can take the test as many times as you
            like, and each time you’ll likely see your speed and accuracy
            improve. So why not give it a try today? You might be surprised at
            how fast you can type.
          </p>
          <div className="text-center block">
            <a
              href="#"
              className="custom-btn-mob font-bold m-auto w-[250px] bg-black text-white items-center border-2 border-gray-700 rounded-xl cursor-pointer flex justify-center max-w-full p-4 relative text-center transition duration-500 whitespace-nowrap no-underline"
            >
              Take typing test now
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
