import React from "react";

function Timer({theme, defaultTime}) {
  return (
    <label
      htmlFor="dashboard-content"
      style={{color: theme.mainColor}}
      className="text-medium capitalize font-bold mb-4 block"
    >
      Time: {defaultTime}
    </label>
  );
}

export default Timer;
