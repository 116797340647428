import React from "react";

export default function CertificateBenefits({theme}) {
  return (
    <section style={{backgroundColor: theme.bgColor}} className="bg-[#006a43] dark:bg-gray-900">
      <div className="banner-tab banner-tab2 certificate-banner-tab2 grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div className="banner-left-tab mr-auto place-self-center md:col-span-7 lg:col-span-7">
          <h2 style={{color: theme.themeName == "default" ? theme.subAltColor : theme.textColor}}  className="max-w-2xl mb-4 mt-4 text-3xl font-extrabold tracking-tight leading-none md:text-2xl xl:text-3xl dark:text-white">
            Benefits of taking typing test
          </h2>
          <ol style={{color: theme.themeName == "default" ? theme.subAltColor : theme.textColor}}  className="list-decimal pl-[50px] max-w-1xl mb-6 font-light lg:mb-8 dark:text-gray-400">
            <li className="my-2">
              There are many reasons why you might want to take a typing speed
              test.{" "}
            </li>
            <li  className="my-2">Perhaps you’re curious to find out how fast you can type.</li>
            <li>
              maybe you want to see if you need to improve your accuracy.{" "}
            </li>
            <li className="my-2">
              Typing speed test is a great way to estimate your progress. The
              average typing speed is 40 words per minute, so if you can beat
              that, you’re doing great!
            </li>
            <li className="my-2">
              You can take the test as many times as you like, and each time
              you’ll likely see your speed and accuracy improve.
            </li>
          </ol>
        </div>
        <div className="banner-left-tab banner-left-tab-cer lg:mt-0 md:col-span-5 lg:col-span-5 lg:flex">
          <img
            className="w-[90%] object-[contain] "
            src="https://img.freepik.com/free-vector/demo-concept-illustration_114360-7612.jpg?w=740&t=st=1693493031~exp=1693493631~hmac=4f9ff440f1825c20d1b2c86b74ee582ae0064b55f2cc092653417f5dfa98fe22"
            alt="mockup"
          />
        </div>
      </div>
    </section>
  );
}
