import React, { createContext, useContext, useRef, useState } from "react";

const ThemeContext = createContext();

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeProvider = ({ children }) => {
  const ref = useRef();
  const [theme, setTheme] = useState(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      ref.current = JSON.parse(storedTheme);
      return JSON.parse(storedTheme);
    }
    // If no theme is stored, provide a default theme here
    return DEFAULT_THEME;
  });

  const [autoSwitch, setAutoSwitch] = useState(() => {
    const storedAutoSwitchTheme = localStorage.getItem("autoSwitchTheme");
    if (storedAutoSwitchTheme) {
      ref.current = JSON.parse(storedAutoSwitchTheme);
      return JSON.parse(storedAutoSwitchTheme);
    }
    // If no auto switch is stored, provide a default false
    return false;
  });

  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
    localStorage.setItem("theme", JSON.stringify(newTheme));
    ref.current = newTheme
  };

  // create a function to update theme autoSwitchTheme value
  const handleAutoSwitchThemeChange = (enableSutoSwitchTheme) => {
    setAutoSwitch(enableSutoSwitchTheme);
    localStorage.setItem("autoSwitchTheme", enableSutoSwitchTheme);
    ref.current = enableSutoSwitchTheme;
  };

  const value = {
    theme,
    autoSwitch,
    handleThemeChange,
    handleAutoSwitchThemeChange,
  };

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

// Define a default theme in case no theme is stored in localStorage
const DEFAULT_THEME = {
    // themeName: "default",
    // bgColor: "#d7fec8",
    // mainColor: "#0b996f",
    // caretColor: "#ff3a32",
    // subColor: "#00000",
    // subAltColor: "#0e0506",
    // textColor: "#4a4d4e",
    // errorColor: "#771b1f",
    // errorExtraColor: "#591317",
    // colorfulErrorColor: "#771b1f",
    // colorfulErrorExtraColor: "#591317",
};