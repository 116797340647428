import React from "react";
import accuracyGif from "../../assets/images/accuracy.gif";
import bronzeCertificate from "../../assets/images/bronze-certificate.png";
import certificateGif from "../../assets/images/certificate.gif";
import goldCertificate from "../../assets/images/gold-certificate.png";
import greenCertificate from "../../assets/images/green-certificate.png";
import silverCertificate from "../../assets/images/silver-certificate.png";


import speedGif from "../../assets/images/speed.gif";
function CertificateInfo({theme}) {
  return (
    <section style={{backgroundColor: theme.bgColor}} className="bg-[#006a43] dark:bg-gray-900">
      <div className="banner-tab banner-tab2 items-center certificate-banner-tab grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div className="banner-left-tab lg:mt-0 md:col-span-6 lg:col-span-6 lg:flex">
          <div className="w-[90%] relative overflow-x-auto px-2 py-1 bg-[#ffff] rounded-[30px] custom-result certificate-custom-result">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-[#7D7676] bg-[#ffff] dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-1 py-2">
                  <img className="row-img" src={certificateGif} alt="certificateGif" />{" "}{" "}{" "}
                    Certificate
                  </th>
                  <th scope="col" className="px-1 py-2">
                  <img className="row-img" src={speedGif} alt="arrowIcn" />{" "}{" "}
                    Speed
                  </th>
                  <th scope="col" className="px-1 py-2">
                    <img className="row-img" src={accuracyGif} alt="speedGif" />{" "}
                    Accuracy
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-[#ffff] dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-1 text-sm py-2 font-medium text-[#000] whitespace-nowrap dark:text-white"
                  >
                    <img
                      className="row-img"
                      src={goldCertificate}
                      alt="premium-qualityIcn"
                    />{" "}
                    Gold
                  </th>
                  <th className="px-1 py-2 text-[#000]">70 wpm</th>
                  <th className="px-1 py-2 text-[#000]">99.99%</th>
                </tr>
                <tr className="bg-[#ffff] dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-1 py-2 font-medium text-[#000] whitespace-nowrap dark:text-white"
                  >
                    <img
                      className="row-img"
                      src={silverCertificate}
                      alt="premium-qualityIcn"
                    />{" "}
                    silver
                  </th>
                  <th className="px-1 py-2 text-[#000]">60 wpm</th>
                  <th className="px-1 py-2 text-[#000]">98.99%</th>
                </tr>
                <tr className="bg-[#ffff] dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-1 py-2 font-medium text-[#000] whitespace-nowrap dark:text-white"
                  >
                    <img
                      className="row-img"
                      src={bronzeCertificate}
                      alt="premium-qualityIcn"
                    />{" "}
                    bronze
                  </th>
                  <th className="px-1 py-2 text-[#000]">55 wpm</th>
                  <th className="px-1 py-2 text-[#000]">97.99%</th>
                </tr>
                <tr className="bg-[#ffff] dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-1 py-2 font-medium text-[#000] whitespace-nowrap dark:text-white"
                  >
                    <img
                      className="row-img"
                      src={greenCertificate}
                      alt="premium-qualityIcn"
                    />{" "}
                    green
                  </th>
                  <th className="px-1 py-2 text-[#000]">40 wpm</th>
                  <th className="px-1 py-2 text-[#000]">96.99%</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="banner-left-tab mr-auto place-self-center md:col-span-6 lg:col-span-6">
          <p style={{color: theme.themeName == "default" ? theme.subAltColor : theme.textColor}}  className="max-w-2xl mb-6 font-light text-[#fff] lg:mb-8 md:text-base lg:text-base dark:text-gray-400">
            Do you know that you can get certified in keyboarding on{" "}
            <a href="#">any layout?</a> That’s right — whether you’re a QWERTY
            fan or prefer DVORAK, there’s a certification test for you. You can
            take the test as many times as you want! Only the{" "}
            <a href="#">best score</a> will count towards your certification,
            there’s no need to worry about making a mistake.
          </p>
        </div>
      </div>
    </section>
  );
}

export default CertificateInfo;
