import React from 'react'

function QuoteSection({theme}) {
  return (
    <section style={{backgroundColor: theme.bgColor}} className='dark:bg-gray-900'>
    <div className="banner-tab banner-tab1 grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div className="m-auto text-center place-self-center lg:col-span-12">
            <h1 style={{color: theme.mainColor}} className={`max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white`}>Revolutionize your typing skills with Crazy Typist </h1>
            <p  style={{color: theme.themeName == "default" ? theme.subAltColor : theme.textColor}} className={`max-w-2xl mb-6 mt-6 font-[400] ${theme.textColorParagraph ? theme.textColorParagraph : "text-[#474747]" } lg:mb-8 md:text-lg lg:text-xl `}>At Crazy Typist, we believe that typing isn't just a skill; it's an art form. Our mission is to empower individuals to become masters of the keyboard, effortlessly gliding through letters and words like a virtuoso pianist. Whether you're a seasoned pro or a newbie to the world of typing, we've got the tools and techniques to transform you into a typing maestro.</p>
            <a
                  href="#metrics"
                  style={{color: theme.themeName == "default" ? theme.textColor : theme.subAltColor , backgroundColor: theme.mainColor}}
                  className="signup-btn align-center bg-black font-medium text-white text-lg rounded-lg py-2.5 box-border cursor-pointer flex justify-center border-2 border-solid border-black relative text-center no-underline transition duration-500 whitespace-nowrap hover:bg-gray-50 focus:ring-4 focus:ring-primary-300 dark:border-brand-charcoal-grey-900 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  See more
                </a>
        </div>               
    </div>
</section>
  )
}

export default QuoteSection