import React from 'react';
import { Link } from 'react-router-dom';
import goldImage from "../../assets/images/demo.png";
import goldCertificateDemo from "../../assets/images/demo2.png";
import greenCertificateDemo from "../../assets/images/demo3.jpg";


function CertificateHeroSection({theme}) {
  return (
    <section style={{backgroundColor: theme.bgColor}} className="dark:bg-gray-900">
    <div className="max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
      <div className="m-auto place-self-center lg:col-span-12">
        <h2 style={{color: theme.mainColor}} className="m-auto text-center max-w-4xl mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl xl:text-4xl dark:text-grey-800">
          Get Your Own Professional Certificate Now
        </h2>
        <div className="lg:flex justify-center items-center py-[40px] flex mobile-certificate-image">
          <div className="certificate-image1 w-[35%] lg:w-1/4">
            <img
              className="w-full h-auto"
              src={greenCertificateDemo}
              alt="Image 1"
            />
          </div>
          <div className="certificate-image2 w-full lg:w-1/2 Z-20">
            <img
              className="w-full h-auto"
              src={goldImage}
              alt="Image 2"
            />
          </div>
          <div className="certificate-image3 w-[35%] lg:w-1/4">
            <img
              className="w-full h-auto"
              src={goldCertificateDemo}
              alt="Image 3"
            />
          </div>
        </div>
        <div className="text-center block">
        <Link
            to="/certificate-test"
            style={{color: theme.subAltColor , backgroundColor: theme.mainColor}}
            className="custom-btn-mob inline-flex bg-[#000] items-center justify-center px-5 py-3 text-base font-medium text-center text-white border-gray-300 rounded-lg hover:bg-white hover:text-[#0b996f] hover:border-[#0b996f] focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
          >
           Get Certificate
          </Link>
        </div>
      </div>
    </div>
  </section>
  )
}

export default CertificateHeroSection
