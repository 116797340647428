import React from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

function LoginForm({ theme }) {
  const { user, loginUser, loading } = React.useContext(AuthContext);

  return (
    <form onSubmit={loginUser}>
      <div className="mb-6">
        <label
          style={{ color: theme.textColor }}
          htmlFor="email"
          className="block mb-2 text-[#006a43] text-sm text-left font-medium dark:text-white"
        >
          Your email
        </label>
        <input
          style={{ backgroundColor: theme.subAltColor, border: "none", color:theme.mainColor }}
          type="email"
          id="email"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Your email"
          name="email"
          required
        />
      </div>
      <div className="mb-6">
        <label
          style={{ color: theme.textColor }}
          htmlFor="password"
          className="block text-[#006a43] text-left mb-2 text-sm font-medium dark:text-white"
        >
          Your password
        </label>
        <input
          style={{ backgroundColor: theme.subAltColor, border: "none", color:theme.mainColor }}
          type="password"
          id="password"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          required
          placeholder="Your password"
          name="password"
        />
      </div>
      <div className="flex items-center justify-between">
        <button
          type="submit"
          style={{ color: theme.subAltColor, backgroundColor: theme.mainColor }}
          disabled={loading}
          className={`bg-[#006a43] text-white px-4 py-2 rounded hover:bg-[#209b6ef2] ${
            loading ? `bg-[#209b6ef2] hover:cursor-not-allowed` : ``
          } focus:outline-none focus:bg-[#209b6ef2] hover:cursor-pointer`}
        >
          Login
        </button>
        <Link style={{color: theme.textColor}} to="/forget-password" className="text-[#006a43] hover:underline">
          Forgot Password?
        </Link>
      </div>
      <Link to="/signup">
        <div  style={{color: theme.textColor}} className="mt-5 text-[#006a43] hover:underline">
          New here? Create account
        </div>
      </Link>
    </form>
  );
}

export default LoginForm;
