import React, { useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { useTheme } from "../../context/themeContext";

function Navbar() {
  const { user, logoutUser, loadingBarRef } = useContext(AuthContext);
  const { theme } = useTheme();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header>
      {/* <LoadingBar color="#f11946" height={3} ref={loadingBarRef} /> */}
      <nav
        style={{ backgroundColor: theme ? theme.bgColor : "#d7fec8" }}
        className="border-gray-200 px-4 lg:px-6 py-6 dark:bg-gray-800"
      >
        <div className="mob-gap flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <NavLink
            to="/"
            style={{ color: theme.mainColor }}
            className="flex items-center text-3xl font-black"
          >
            CrazyTypist
          </NavLink>
          <div className="flex items-center lg:order-2">
            {user ? (
              <>
                <Link
                  to="/profile"
                  style={{ color: theme.mainColor }}
                  className="signup-btn w-[150px] dark:text-white hover:bg-gray-50 hover:text-green-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-lg px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
                >
                  <i className="fa fa-user mr-2"></i>
                  Profile
                </Link>
                <i
                  style={{ color: theme.mainColor }}
                  className="fa fa-sign-out ml-2 mr-5 hover:text-green-800 cursor-pointer"
                  onClick={logoutUser}
                ></i>
              </>
            ) : (
              <>
                <NavLink
                  to="/login"
                  style={{ color: theme.mainColor }}
                  className="signup-btn w-[170px] text-gray-800 dark:text-white hover:bg-gray-50 hover:text-green-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-lg px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
                >
                  Log in
                </NavLink>
                <NavLink
                  to="/signup"
                  style={{
                    color: theme.subAltColor,
                    backgroundColor: theme.mainColor,
                  }}
                  className="signup-btn align-center bg-black font-medium text-white text-lg rounded-lg py-2.5 box-border cursor-pointer flex justify-center w-full max-w-full border-2 border-solid border-black relative text-center no-underline transition duration-500 whitespace-nowrap hover:bg-gray-50 hover:text-green-800 hover:border-green-800 focus:ring-4 focus:ring-primary-300 dark:border-brand-charcoal-grey-900 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Get started
                </NavLink>
              </>
            )}

            <button
              data-collapse-toggle="mobile-menu-2"
              type="button"
              className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden focus:outline-none dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="mobile-menu-2"
              aria-expanded={isMobileMenuOpen}
              onClick={toggleMobileMenu}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className={`w-6 h-6 ${isMobileMenuOpen ? "hidden" : "block"}`}
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <svg
                className={`w-6 h-6 ${isMobileMenuOpen ? "block" : "hidden"}`}
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div
            className={`menu-list ${
              isMobileMenuOpen ? "flex" : "hidden"
            } justify-between items-center w-full lg:flex lg:w-auto lg:order-1`}
            id="mobile-menu-2"
          >
            {/* <!-- ... your menu items ... --> */}
            <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
              <li>
                <NavLink
                  to="/"
                  style={{ color: theme.mainColor }}
                  className="block py-2 pr-4 pl-3 rounded bg-primary-700 text-lg lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
                  aria-current="page"
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about"
                  style={{ color: theme.mainColor }}
                  className="block py-2 pr-4 pl-3 text-gray-700 border-b text-lg border-gray-100 hover:text-green-800 hover:underline lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                >
                  About
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/get-certificate"
                  style={{ color: theme.mainColor }}
                  className="block py-2 pr-4 pl-3 text-gray-700 border-b text-lg border-gray-100 hover:text-green-800 hover:underline lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Certificate
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/leaderboard"
                  style={{ color: theme.mainColor }}
                  className={`block py-2 pr-4 pl-3 text-lg border-b border-gray-100 hover:text-[${theme.textColor}] hover:underline lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700`}
                >
                  Leaderboard
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/settings"
                  style={{ color: theme.mainColor }}
                  className={`block py-2 pr-4 pl-3 text-lg border-b border-gray-100 hover:text-[${theme.textColor}] hover:underline lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700`}
                >
                  Settings
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Navbar;
