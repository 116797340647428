import React from "react";
import { CRAZY_TYPIST_THEMES } from "../../constants/constants";

export default function ThemeSettings({ theme, autoSwitchTheme, handleThemeChange, handleAutoSwitchThemeChange, resetThemeSettings }) {
  const [currentAutoSwitch, setCurrentAutoSwitch] = React.useState(autoSwitchTheme);
  const themeOptions = CRAZY_TYPIST_THEMES;

  return (
    <div className="setting-content-outer w-[80%] border rounded-lg">
      <div className="setting-content block py-[30px] px-[50px]">
        <div className="flex flex-wrap items-center">
          <div className="block w-[50%]">
            <span
              style={{ color: theme.mainColor }}
              className="mb-3 font-light text-[18px] text-[#808080]"
            >
              Auto switch theme
            </span>
            <p
              style={{ color: theme.subColor }}
              className="max-w-xl mb-6 font-light text-[15px] text-black"
            >
              Enabling this will automatically switch the theme between below
              options.
            {
              currentAutoSwitch ? <span className="mt-5" style={{color: theme.mainColor}}>Auto Switch enabled</span> : <span className="mt-5" style={{color: theme.errorColor}}>Auto Switch disabled</span>
            }
            </p>
          </div>
          <div className="st-btn flex flex-wrap w-[50%] justify-end">
            <button
              type="button"
              style={{
                backgroundColor: theme.mainColor,
                color: theme.subAltColor,
              }}
              onClick={() => {
                handleAutoSwitchThemeChange(true);
                setCurrentAutoSwitch(true);
              }}
              className="w-[24%] text-white hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            >
              On
            </button>

            <button
              type="button"
              style={{
                backgroundColor: theme.mainColor,
                color: theme.subAltColor,
              }}
              onClick={() => {
                handleAutoSwitchThemeChange(false);
                setCurrentAutoSwitch(false);
              }}
              className="w-[24%] text-white hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            >
              Off
            </button>
          </div>
        </div>

        <div className="flex flex-wrap items-center">
          <div className="block w-[50%]">
            <span
              style={{ color: theme.mainColor }}
              className="mb-3 font-light text-[18px] text-[#808080]"
            >
              Reset Theme
            </span>
            <p
              style={{ color: theme.subColor }}
              className="max-w-xl mb-6 font-light text-[15px] text-black"
            >
              Resets theme to the default.
            </p>
          </div>
          <div className="st-btn flex flex-wrap w-[50%] justify-end">

            <button
              type="button"
              style={{
                backgroundColor: theme.mainColor,
                color: theme.subAltColor,
              }}
              onClick={() => {
                resetThemeSettings();
              }}
              className="w-[50%] text-white hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            >
              Restore default theme
            </button>
          </div>
        </div>
        
        <span
          style={{ color: theme.mainColor }}
          className="block mb-3 font-light text-[20px] text-[#808080]"
        >
          Theme
        </span>
        <div className="setting-btns flex flex-wrap justify-center gap-[10px]">
          {themeOptions.map((themeOption, i) => (
            <button
              key={i}
              onClick={() => handleThemeChange(themeOption)}
              id={themeOption.id}
              type="button"
              style={{
                backgroundColor: themeOption.bgColor,
                color: themeOption.mainColor,
              }}
              className={`w-[32%] hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-[${themeOption.mainColor}] dark:focus:ring-[${themeOption.mainColor}] font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
            >
              <div className="absolute z-0 -ml-2 mt-[2px] group-hover:scale-125 transition-transform">
                <span
                  style={{ backgroundColor: themeOption.mainColor }}
                  className="inline-block w-4 h-4 mr-1 rounded-full"
                ></span>
                <span
                  style={{ backgroundColor: themeOption.subColor }}
                  className="inline-block w-4 h-4 mr-1 rounded-full"
                ></span>
                <span
                  style={{ backgroundColor: themeOption.textColor }}
                  className="inline-block w-4 h-4 rounded-full"
                ></span>
              </div>
              <div>{themeOption.themeName}</div>
            </button>
          ))}
        </div>
        <input type="color" className="p-2 fixed top-54 right-0" />
      </div>
    </div>
  );
}
