import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AuthContext from '../../context/AuthContext';

function RegisterForm({theme}) {
  let {registerUser, loading, formErrors} = useContext(AuthContext)
  return (
    <form onSubmit={registerUser}>
      <div className="mb-6">
        <label
          style={{ color: theme.textColor}}
          htmlFor="username"
          className="block mb-2 text-[#006a43] text-sm text-left font-medium dark:text-white"
        >
          Your username
        </label>
        <input
          style={{ backgroundColor: theme.subAltColor, border: "none", color: theme.mainColor }}
          type="text"
          id="username"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Your username"
          required
          name="username"
        />

        {formErrors.username && <p style={{ color: theme.errorColor }} className="text-red-500 text-xs mt-1">{formErrors.username}</p>}

      </div>
      <div className="mb-6">
        <label
          style={{ color: theme.textColor }}
          htmlFor="email"
          className="block mb-2 text-[#006a43] text-sm text-left font-medium dark:text-white"
        >
          Your email
        </label>
        <input
                  style={{ backgroundColor: theme.subAltColor, border: "none", color: theme.mainColor }}

          type="email"
          id="email"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Your email"
          required
          name="email"
        />

        {formErrors.email && <p style={{ color: theme.errorColor }} className="text-red-500 text-xs mt-1">{formErrors.email}</p>}
      </div>
      <div className="mb-6">
        <label
          style={{ color: theme.textColor }}
          htmlFor="password"
          className="block text-[#006a43] text-left mb-2 text-sm font-medium dark:text-white"
        >
          Your password
        </label>
        <input
          type="password"
          style={{ backgroundColor: theme.subAltColor, border: "none", color: theme.mainColor}}

          id="password"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Your Password"
          name="password"
          required
        />
      </div>
      <div className="flex items-center justify-between">
        <button
          type="submit"
          disabled={loading}
          style={{ color: theme.subAltColor, backgroundColor: theme.mainColor }}
          className={`bg-[#006a43] text-white px-4 py-2 rounded hover:bg-[#209b6ef2] ${loading ? `bg-[#209b6ef2] hover:cursor-not-allowed` : `` } focus:outline-none focus:bg-[#209b6ef2] hover:cursor-pointer`}
        >
          create account
        </button>
        <Link style={{ color: theme.textColor }} to="/login" className="text-[#006a43] hover:underline">
          already have account?
        </Link>
      </div>
    </form>
  );
}

export default RegisterForm;
