import React, { Suspense } from "react";
const Marquee = React.lazy(() => import("react-fast-marquee"));

function ReviewSection() {
  return (
    <section className="bg-[#6358de] dark:bg-gray-900">
      <div className="max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div className="m-auto place-self-center lg:col-span-12">
          <h2 className="m-auto text-center text-white max-w-2xl mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl xl:text-4xl dark:text-white">
            See what our customers are saying
          </h2>
          <p className="m-auto text-center max-w-2xl mb-6 font-light text-white lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
            Our customers love us. We have the highest ratings in the industry.
          </p>
        </div>
        <div className="mob-center flex items-center flex-wrap gap-18 justify-between">
          <Suspense fallback={<div>Loading...</div>}>
            <Marquee
              className="marquee-container cursor-pointer"
              pauseOnHover={true}
              gradient={200}
              gradientColor={[99, 88, 222]}
            >
              <div className="w-[90%] h-[14rem] bg-[#6358de] rounded-md shadow-lg drop-shadow-lg border-[0.01rem] border-gray-300 ml-[4rem]">
                <div className="p-[1rem] w-full h-full flex justify-center items-center">
                  <div className="inner-container-content">
                    <img
                      alt="awardlogo-0"
                      loading="lazy"
                      decoding="async"
                      data-nimg="1"
                      className="w-fit h-fit mx-auto"
                      style={{ color: "transparent" }}
                      src="https://corp-backend.brevo.com/wp-content/uploads/2023/04/Michelin-1.svg"
                    />
                    <h1 className="text-center text-white font-bold">Rajesh Kumar</h1>
                    <p className="whitespace-pre-wrap text-gray-300 text-sm">
                      This company has transformed our typing speed and accuracy. Highly recommended!.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-[90%] h-[14rem] bg-[#6358de] rounded-md shadow-lg drop-shadow-lg border-[0.01rem] border-gray-300 ml-[4rem]">
                <div className="p-[1rem] w-full h-full flex justify-center items-center">
                  <div className="inner-container-content">
                    <img
                      alt="awardlogo-0"
                      loading="lazy"
                      decoding="async"
                      data-nimg="1"
                      className="w-fit h-fit mx-auto"
                      style={{ color: "transparent" }}
                      src="https://corp-backend.brevo.com/wp-content/uploads/2023/04/Michelin-1.svg"
                    />
                    <h1 className="text-center text-white font-bold">Priya Sharma</h1>
                    <p className="whitespace-pre-wrap text-gray-300 text-sm">
                      Exceptional service and outstanding results. My hands are now more productive than ever.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-[90%] h-[14rem] bg-[#6358de] rounded-md shadow-lg drop-shadow-lg border-[0.01rem] border-gray-300 ml-[4rem]">
                <div className="p-[1rem] w-full h-full flex justify-center items-center">
                  <div className="inner-container-content">
                    <img
                      alt="awardlogo-0"
                      loading="lazy"
                      decoding="async"
                      data-nimg="1"
                      className="w-fit h-fit mx-auto"
                      style={{ color: "transparent" }}
                      src="https://corp-backend.brevo.com/wp-content/uploads/2023/04/Michelin-1.svg"
                    />
                    <h1 className="text-center text-white font-bold">Anil Verma</h1>
                    <p className="whitespace-pre-wrap text-gray-300 text-sm">
                      Their typing solutions are top-notch. I saw immediate improvements in my workflow.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-[90%] h-[14rem] bg-[#6358de] rounded-md shadow-lg drop-shadow-lg border-[0.01rem] border-gray-300 ml-[4rem]">
                <div className="p-[1rem] w-full h-full flex justify-center items-center">
                  <div className="inner-container-content">
                    <img
                      alt="awardlogo-0"
                      loading="lazy"
                      decoding="async"
                      data-nimg="1"
                      className="w-fit h-fit mx-auto"
                      style={{ color: "transparent" }}
                      src="https://corp-backend.brevo.com/wp-content/uploads/2023/04/Michelin-1.svg"
                    />
                    <h1 className="text-center text-white font-bold">Kavita Patel</h1>
                    <p className="whitespace-pre-wrap text-gray-300 text-sm">
                      A game-changer for my work life. The best typing website I've ever practiced with.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-[90%] h-[14rem] bg-[#6358de] rounded-md shadow-lg drop-shadow-lg border-[0.01rem] border-gray-300 ml-[4rem]">
                <div className="p-[1rem] w-full h-full flex justify-center items-center">
                  <div className="inner-container-content">
                    <img
                      alt="awardlogo-0"
                      loading="lazy"
                      decoding="async"
                      data-nimg="1"
                      className="w-fit h-fit mx-auto"
                      style={{ color: "transparent" }}
                      src="https://corp-backend.brevo.com/wp-content/uploads/2023/04/Michelin-1.svg"
                    />
                    <h1 className="text-center text-white font-bold">Sunil Gupta</h1>
                    <p className="whitespace-pre-wrap text-gray-300 text-sm">
                      Professional and efficient. Their typing services have greatly benefited our operations.
                    </p>
                  </div>
                </div>
              </div>
            </Marquee>
          </Suspense>
        </div>
      </div>
    </section>
  );
}

export default ReviewSection;