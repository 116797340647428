import React from 'react';

function CertificateTestOptions({setDefaultWordsLimit}) {
    const handleWordLengthChange = (event) => {
        setDefaultWordsLimit(event.target.value);
      };

  return (
    <div className="certificate-test-options">
      <div className="option-container">
        <label htmlFor="wordLength">Word Length:</label>
        <select id="wordLength" name="wordLength" onChange={handleWordLengthChange}>
          <option value="10">10 words</option>
          <option value="20">20 words</option>
          <option value="30">30 words</option>
          <option value="40">40 words</option>
          <option value="50">50 words</option>
        </select>
      </div>
    </div>
  );
}

export default CertificateTestOptions;