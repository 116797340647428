import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import arrowIcn from "../assets/images/arrowIcn.png";
import certificateIcn from "../assets/images/certificateIcn.png";
import deadlineIcn from "../assets/images/deadlineIcn.png";
import premiumQualityIcn from "../assets/images/premium-qualityIcn.png";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import AuthContext from "../context/AuthContext";

export default function CertificateResultPage({ theme }) {
  let locationVars = useLocation();

  const { user } = useContext(AuthContext);

  const downloadCertificate = async (imageURL) => {
    if (!user) {
      window.location.href = "/login";
      return;
    }
    const response = await fetch(imageURL);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "certificate.png");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <>
      <Navbar />
      <section
        style={{ backgroundColor: theme.bgColor }}
        className={`bg-[${theme.bgColor}] dark:bg-gray-900`}
      >
        <div className="banner-tab banner-tab1 grid max-w-screen-xl px-4 py-4 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="m-auto text-center place-self-center lg:col-span-12">
            <h2
              style={{ color: theme.mainColor }}
              className="max-w-5xl mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl xl:text-5xl dark:text-white"
            >
              {locationVars.state.stats.image
                ? `Hurray! You got the Certificate`
                : `Oops You didn't get the Certificate`}
            </h2>
            <div className="certificate-img mt-[30px] mb-[30px]">
              {locationVars.state.stats.image && (
                <img
                  onContextMenu={(e) => e.preventDefault()}
                  className="m-auto"
                  src={locationVars.state.stats.image}
                  alt="certificate"
                  style={{ maxWidth: "800px", maxHeight: "600px" }}
                />
              )}
            </div>
            <div className="p-4 result-outer get-result-outer">
              <div className="flex flex-wrap items-center text-center justify-center gap-[5px]">
                <div className="relative overflow-x-auto px-2 py-1 bg-[#D9D9D9] rounded-[30px] custom-result">
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-[#7D7676] bg-[#D9D9D9] dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-1 py-2">
                          <img
                            className="row-img"
                            src={certificateIcn}
                            alt="certificateIcn"
                            onContextMenu={(e) => e.preventDefault()}
                          />{" "}
                          Certificate
                        </th>
                        <th scope="col" className="px-1 py-2">
                          <img
                            className="row-img"
                            src={deadlineIcn}
                            alt="deadlineIcn"
                          />{" "}
                          Speed
                        </th>
                        <th scope="col" className="px-1 py-2">
                          <img
                            className="row-img"
                            src={arrowIcn}
                            alt="arrowIcn"
                          />{" "}
                          Accuracy
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-[#D9D9D9] dark:bg-gray-800 dark:border-gray-700">
                        <th
                          scope="row"
                          className="px-1 py-2 font-medium text-[#000] whitespace-nowrap dark:text-white"
                        >
                          <img
                            className="row-img"
                            src={premiumQualityIcn}
                            alt="premium-qualityIcn"
                          />{" "}
                          {locationVars.state.stats.certificate_type}
                        </th>
                        <th className="px-1 py-2 text-[#000]">
                          {" "}
                          {locationVars.state.stats.typing_speed.toFixed(2)} wpm
                        </th>
                        <th className="px-1 py-2 text-[#000]">
                          {locationVars.state.stats.accuracy.toFixed(2)} %
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="text-center flex downlaod-btn">
              {locationVars.state.stats.image && (
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    downloadCertificate(locationVars.state.stats.image);
                  }}
                  className="custom-btn-mob font-bold m-auto w-[300px] bg-black text-white items-center border-2 border-gray-700 rounded-xl cursor-pointer flex justify-center max-w-full p-4 relative text-center transition duration-500 whitespace-nowrap no-underline"
                >
                  {user
                    ? `Download certificate`
                    : `Login to Download certificate`}
                </a>
              )}

              <Link
                to="/certificate-test"
                className="custom-btn-mob font-bold m-auto w-[150px] bg-black text-white items-center border-2 border-gray-700 rounded-xl cursor-pointer flex justify-center max-w-full p-4 relative text-center transition duration-500 whitespace-nowrap no-underline"
              >
                Try again
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
