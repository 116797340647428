import React, { createContext, useContext, useRef, useState } from "react";
import { DEFAULT_SOUND_SETTINGS } from "../constants/constants";

export const SoundContext = createContext();

export const useSoundSettings = () => {
  return useContext(SoundContext);
};

export const SoundProvider = ({ children }) => {
  const ref = useRef();
  const [soundSettings, setSoundSettings] = useState(() => {
    const storedSoundSettings = localStorage.getItem("soundSettings");
    if (storedSoundSettings) {
      ref.current = JSON.parse(storedSoundSettings);
      return JSON.parse(storedSoundSettings);
    }
    // If no sound settings is stored, provide a default sound setting here
    return DEFAULT_SOUND_SETTINGS;
  });

  const handleSoundSettingsChange = (newSoundSettings) => {
    setSoundSettings(newSoundSettings);
    localStorage.setItem("soundSettings", JSON.stringify(newSoundSettings));
    ref.current = newSoundSettings;
  };

  const value = {
    soundSettings,
    handleSoundSettingsChange,
  };

  return <SoundContext.Provider value={value}>{children}</SoundContext.Provider>;
};
