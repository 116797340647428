import { motion } from "framer-motion";
import React from "react";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
export default function TermsPage({theme}) {
  return (
    <>
      <motion.main
        initial={{ width: 0, transition: { duration: 0.05 } }}
        animate={{ width: "100%" }}
        exit={{ width: window.innerWidth, transition: { duration: 0.05 } }}
        smooth={true}
      >
        <Navbar />
        <section style={{backgroundColor: theme.bgColor, color: theme.mainColor}} className="bg-[#d7fec8] dark:bg-gray-900">
          <div>
            <div className="container mx-auto py-8 px-4">
              <h1 className="text-3xl font-bold mb-4">Terms And Condition</h1>
              <p className="mb-4">Effective date: September 8, 2021</p>
              <p className="mb-4">Last updated: Oct 24, 2023</p>
              <p className="mb-4">
                These Terms and Conditions ("Terms") govern your access to and use of the CrazyType website (the "Service"). <br/> By accessing or using the Service, you agree to be bound by these Terms. <br/> If you disagree with any part of the Terms, you may not access or use the Service.
              </p>

              <h2 className="text-2xl font-bold mt-8 mb-4">
                1. Account Creation
              </h2>
              <p className="mb-4">You may create an account on the Service (an "Account"). <br/> You are responsible for maintaining the confidentiality of your account information and password and are fully responsible for all activities that occur under your Account. <br/> You agree to keep your account information accurate and up-to-date.</p>
              <h2 className="text-2xl font-bold mt-8 mb-4">
                2. User Conduct

              </h2>
              <p className="mb-4">You agree to use the Service in a lawful and respectful manner. You agree not to:</p>
              <p className="mb-4">Use the Service for any illegal or unauthorized purpose.</p>
              <p className="mb-4">Transmit any harmful or malicious content, including viruses or spyware.
              </p>
              <p className="mb-4">Disrupt the normal operation of the Service.
              </p>
              <p className="mb-4">Impersonate any person or entity.
              </p>

              <h2 className="text-2xl font-bold mt-8 mb-4">
                3. Content ownership
              </h2>
              <p className="mb-4">You retain all ownership rights to the typing data you submit to the Service. However, by submitting your typing data, you grant CrazyType a non-exclusive, royalty-free license to use, reproduce, modify, publish, and distribute your typing data for the purposes of improving the Service.
              </p>

              <h2 className="text-2xl font-bold mt-8 mb-4">
                4. Disclaimers

              </h2>
              <p className="mb-4">The Service is provided "as is" and "as available" without warranties of any kind, express or implied. CrazyType does not warrant that the Service will be uninterrupted, error-free, or virus-free.</p>

              <h2 className="text-2xl font-bold mt-8 mb-4">
              5. Limitation of Liability
              </h2>
              <p className="mb-4">CrazyType shall not be liable for any damages arising out of or related to your use of the Service, including, but not limited to, direct, indirect, incidental, consequential, or punitive damages.</p>

              <h2 className="text-2xl font-bold mt-8 mb-4">
              6. Termination

              </h2>
              <p className="mb-4">CrazyType may terminate your access to the Service at any time, for any reason, with or without notice. You may also terminate your account at any time.</p>

              <h2 className="text-2xl font-bold mt-8 mb-4">
              7. Governing Law
              </h2>
              <p className="mb-4">These Terms shall be governed by and construed in accordance with the laws of Indian Government.
              </p>

              <h2 className="text-2xl font-bold mt-8 mb-4">
              8. Entire Agreement

              </h2>
              <p className="mb-4">These Terms constitute the entire agreement between you and CrazyType regarding your use of the Service.</p>

              <h2 className="text-2xl font-bold mt-8 mb-4">
               9. Changes to the Terms

              </h2>
              <p className="mb-4">CrazyType may update these Terms at any time. We will notify you of any changes by posting the new Terms on the Service. Your continued use of the Service after the posting of the revised Terms constitutes your acceptance of the revised Terms.</p>

              <h2 className="text-2xl font-bold mt-8 mb-4">
              10. Contact Us
              </h2>

              <p className="mb-4">If you have any questions about these Terms, please contact us at: <a href="mailto:crazytypist24@gmail.com" className="text-blue-500">crazytypist24@gmail.com</a>
              </p>
            </div>
          </div>
        </section>
        <Footer />
      </motion.main>
    </>
  );
}
