import React, { useContext, useEffect, useState } from "react";
import LeaderBoardPreferences from "../components/LeaderBoardComponents/LeaderBoardPreferences";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import TestContext from "../context/TestContext";

export default function LeaderBoardPage({theme}) {
  const { getLeaderBoardData } = useContext(TestContext);
  const [time, setTime] = useState(10);
  const [allTime, setAllTime] = useState(true);
  const [today, setToday] = useState(false);
  const [userTestResults, setUserTestResults] = useState([]);

  const [preferences, setPreferences] = useState({
    time,
    allTime,
    today,
  });

  useEffect(() => {
    const newPreferences = { time, allTime, today };
    setPreferences(newPreferences);

    const fetchData = async () => {
      try {
        const data = await getLeaderBoardData(newPreferences);
        setUserTestResults(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [time, allTime, today]);

  return (
    <>
      <Navbar />
      <LeaderBoardPreferences
        setAllTime={setAllTime}
        setToday={setToday}
        setTime={setTime}
        time={time}
        allTime={allTime}
        today={today}
        theme={theme}
      />
      <section style={{backgroundColor: theme.bgColor}} >
        <div className="flex flex-col p-5">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="overflow-hidden">
                <table id="leaderboard-table" className="divide-y divide-gray-200 dark:divide-neutral-700 w-full mx-auto">
                  <thead>
                    <tr className="">
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                      >
                        Rank
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                      >
                        WPM
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                      >
                        Accuracy
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-end text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                      >
                        Date
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ backgroundColor: theme.bgColor , color: theme.themeName == "default" ? theme.subAltColor : theme.mainColor}} className="divide-y divide-gray-200 dark:divide-neutral-700">
                    {userTestResults && userTestResults.length > 0 ? (
                      userTestResults.map((result, index) => (
                        <tr key={index}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium w-full text-start">
                            {result.rank}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium w-full text-start">
                            {result.user.username}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm  w-full text-start">
                            {result.wpm}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm  w-full text-start">
                            {result.accuracy}%
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-start text-sm font-medium w-full">
                            {result.date}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={4}
                          className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200"
                        >
                          No results found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
