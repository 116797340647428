import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { useTheme } from "../../context/themeContext";


function Footer() {
  const { theme } = useTheme();
  const { settings } = useContext(AuthContext);
  return (
    <footer style={{ backgroundColor: theme.bgColor }}className="dark:bg-gray-900">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <ul className="mob-center flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
            <li>
              <Link style={{color: theme.mainColor}} to="/contact" className="mr-4 hover:underline hover:text-white md:mr-6 ">
                contact
              </Link>
            </li>
            <li>
              <Link style={{color: theme.mainColor}} to="/privacy-policy" className="mr-4 hover:underline hover:text-white md:mr-6">
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link style={{color: theme.mainColor}} to="/terms" className="mr-4 hover:underline hover:text-white md:mr-6 ">
                term
              </Link>
            </li>
          </ul>
          <ul className="mob-center flex capitalize flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
            <li>
              <Link style={{ backgroundColor: theme ? theme.subColor : "" }}  to={settings.twitter_url} target="_blank" className="socail-link mr-4 hover:underline md:mr-6 ">
                <i className="fa-brands fa-twitter"></i>
              </Link>
            </li>
            <li>
              <Link style={{ backgroundColor: theme ? theme.subColor : "" }} to={settings.facebook_url} target="_blank" className="socail-link mr-4 hover:underline md:mr-6">
                <i className="fa-brands fa-facebook-f"></i>
              </Link>
            </li>
            <li>
              <Link style={{ backgroundColor: theme ? theme.subColor : "" }} to={settings.instagram_url} target="_blank" className="socail-link mr-4 hover:underline md:mr-6 ">
                <i className="fa-brands fa-instagram"></i>
              </Link>
            </li>
          </ul>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8 lg:mt-[100px]" />
        <span className="custom-center block text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © Copyright 2022, All Rights Reserved by CrazyTypist
        </span>
      </div>
    </footer>
  );
}

export default Footer;
