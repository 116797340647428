import React from 'react'

function MetricSection({theme}) {
  return (
    <section style={{backgroundColor: theme ? theme.bgColor : "white"}} className="dark:bg-gray-900" id="metrics">
    <div className="banner-tab banner-tab1 grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div className="banner-left-tab mr-auto place-self-center lg:col-span-5">
            <h2 style={{color: theme.mainColor}} className={`max-w-2xl mb-4 text-3xl text-[#0b996f]  font-extrabold tracking-tight leading-none md:text-4xl xl:text-5xl dark:text-white`}>Numbers are telling our story</h2>
            <p style={{color: theme.themeName == "default" ? theme.subAltColor : theme.textColor}} className={`max-w-2xl mb-6 mt-6 font-[400] ${theme.textColor ? theme.textColor : "text-[#474747]"} lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400`}>In the realm of online learning platforms, numbers serve as poignant indicators of progress and achievement. Within the bustling virtual halls of CrazyTypist, where users embark on a journey to master the art of typing with lightning speed and unwavering accuracy, these numbers paint a vivid picture of dedication, perseverance, and triumph.</p> 
        </div> 
        <div style={{backgroundColor: theme.subColor}} className="banner-left-tab lg:mt-0 lg:col-span-7 lg:flex">
        <div style={{backgroundColor: theme.subColor}} className="custom-crd p-4 w-[60%] m-auto bg-white bg-opacity-70 rounded-lg md:p-8 dark:bg-gray-800">
            <dl className="flex flex-wrap gap-[25px]">
                <div className="w-full flex flex-col items-center justify-center">
                    <dt  style={{color: theme.themeName == "default" ? theme.subAltColor : theme.textColor}} className="mb-2 text-3xl font-extrabold">2M+</dt>
                    <dd style={{color: theme.themeName == "default" ? theme.subAltColor : theme.textColor}} className="text-gray-500 dark:text-gray-400">test started</dd>
                </div>
                <div className="w-full flex flex-col items-center justify-center">
                    <dt style={{color: theme.themeName == "default" ? theme.subAltColor : theme.textColor}} className="mb-2 text-3xl font-extrabold">46K+</dt>
                    <dd  style={{color: theme.themeName == "default" ? theme.subAltColor : theme.textColor}} className="text-gray-500 dark:text-gray-400">test started</dd>
                </div>
                <div className="w-full flex flex-col items-center justify-center">
                    <dt style={{color: theme.themeName == "default" ? theme.subAltColor : theme.textColor}} className="mb-2 text-3xl font-extrabold">99%</dt>
                    <dd style={{color: theme.themeName == "default" ? theme.subAltColor : theme.textColor}} className="text-gray-500 dark:text-gray-400">test started</dd>
                </div>
            </dl>
        </div>
        </div>                
    </div>
</section>
  )
}

export default MetricSection