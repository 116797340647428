import { motion } from "framer-motion";
import React, { useContext } from "react";
import LoadingBar from "react-top-loading-bar";
import AuthContext from "../context/AuthContext";
import TypingSection from "../sections/PracticeDashboardSections/TypingSection";

function TypingDashboard({theme, currentSoundSettings}) {
  const { loadingBarRef } = useContext(AuthContext);
  return (
    <>
      <LoadingBar color={theme.mainColor} ref={loadingBarRef} />
      <motion.main
        initial={{ width: 0, transition: { duration: 0.05 } }}
        animate={{ width: "100%" }}
        exit={{ width: window.innerWidth, transition: { duration: 0.05 } }}
        smooth={true}
      >
        <TypingSection theme={theme} currentSoundSettings={currentSoundSettings}/>
      </motion.main>
    </>
  );
}

export default TypingDashboard;
