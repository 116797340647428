import toast from "react-hot-toast";

function returnToast(message, options = {}) {
  const defaultOptions = {
    duration: 4000,
    position: 'top-center',
    style: {},
    className: '',
    icon: '👏',
    iconTheme: {
      primary: '#000',
      secondary: '#fff',
    },
    ariaProps: {
      role: 'status',
      'aria-live': 'polite',
    },
  };

  const toastOptions = { ...defaultOptions, ...options };

  toast(message, toastOptions);
}

export { returnToast };
