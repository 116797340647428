import { generate } from "random-words";
import { COMMON_WORDS, DEFAULT_DIFFICULTY, DEFAULT_WORDS_COUNT, ENGLISH_MODE, PUNCTUATIONS, QUOTES } from "../../constants/constants";
import { createObjectFromDate, randomIntFromRange } from "../../utils/generateRandomNumber";
import shuffleArray from "../../utils/shuffle";

const wordsGenerator = (wordsCount, difficulty, languageMode, 
                        includeNumbers=false, includePunctuation=false, 
                        baseLayerQuotes=false, baseLayerWords=false) => {
  if (languageMode === ENGLISH_MODE) {
   
    if (difficulty === DEFAULT_DIFFICULTY) {
      const EnglishWordList = [];
      const words = [];

      if (!baseLayerQuotes) {
        for (let i = 0; i < wordsCount; i++) {
          const rand = randomIntFromRange(0, 550);
          EnglishWordList.push(COMMON_WORDS[rand]);
        }

        const randomWordsGenerated = generate({ exactly: wordsCount, maxLength: 7 });
        for (let i = 0; i < wordsCount; i++) {
            words.push({key: randomWordsGenerated[i], val: randomWordsGenerated[i]});
        }
      }

      if (includePunctuation && includeNumbers) {
        for (let i = 0; i < wordsCount / 2; i++) {
            const rand = randomIntFromRange(0, wordsCount);
            EnglishWordList.push(PUNCTUATIONS[rand]);
        }
    
        for (let i = 0; i < wordsCount / 2; i++) {
            const date = generateRandomDate();
            EnglishWordList.push(date);
        }
    } else if (includePunctuation) {
        for (let i = 0; i < wordsCount; i++) {
            const rand = randomIntFromRange(0, wordsCount);
            EnglishWordList.push(PUNCTUATIONS[rand]);
        }
    } else if (includeNumbers) {
        for (let i = 0; i < wordsCount; i++) {
            const date = generateRandomDate();
            EnglishWordList.push(date);
        }
    }

      if (baseLayerQuotes) {
          for (let i = 0; i < DEFAULT_WORDS_COUNT; i++) {
              const rand = randomIntFromRange(0, 20);
              EnglishWordList.push(QUOTES[rand]);
          }
      }

      let finalList = shuffleArray([...EnglishWordList, ...words]);
      finalList = finalList.filter((item) => item !== undefined);

      return [...finalList];
    }
  }
  return ["something", "went", "wrong"];
};

// write function which takes numbers and return elements like dates with month and year
function generateRandomDate() {
  const start = new Date(2012, 0, 1);
  const end = new Date();
  const date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const dateString = `${day} ${monthNames[monthIndex]} ${year}`;
  const dateObject = createObjectFromDate(dateString);
  return dateObject;
}

export default wordsGenerator;
