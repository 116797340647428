import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useSound from "use-sound";
import wordsGenerator from "../../assets/scripts/wordGenerator";
import errorSound from "../../assets/sounds/errorsound.wav";
import keyboardSound from "../../assets/sounds/keyboardsound.mp3";
import Timer from "../../components/PraticeDashboardComponents/Timer";
import WordLimit from "../../components/PraticeDashboardComponents/WordLimit";
import { SoundContext } from "../../context/soundContext";
import ResultModal from "./ResultModal";


import {
  DEFAULT_TIME,
  DEFAULT_TIME_15,
  DEFAULT_TIME_30,
  DEFAULT_TIME_60,
  DEFAULT_VOLUME_OPTIONS_VALUES,
  DEFAULT_WORD_10,
  DEFAULT_WORD_100,
  DEFAULT_WORD_20,
  DEFAULT_WORD_200,
  DEFAULT_WORD_50,
} from "../../constants/constants";

import "../../index.css";

import WordLimitModal from "../../components/PraticeDashboardComponents/WordLimitModal";
import TestAPIContext from "../../context/TestContext";
import { returnToast } from "../../utils/helper";
import { useTypingTest } from "./TypistCustomHook";

document.body.style.cursor = "text";

function TypingSection({ theme, currentSoundSettings }) {
  /**
   * Populate Content Algorithm.
   * 1. Base content will be words or quotes.
   * 2. Show result base on time or words length/quotes length.
   * 3. User can populate base content with numbers and punctuations.
   * 
   * Words length can be increased from length choices, default is 10 words.
   * Time can be increased from time choices, default is 10 seconds.
  */
  
  // sounds setting vars
  const defaultVolumeOptionsValues = DEFAULT_VOLUME_OPTIONS_VALUES;
  const { soundSettings, setSoundSettings } = useContext(SoundContext);
  const [playSound] = useSound(keyboardSound, {
    volume: soundSettings.volume
      ? defaultVolumeOptionsValues[soundSettings?.volume]
      : defaultVolumeOptionsValues["medium"],

    // `interrupt` ensures that if the sound starts again before it's
    // ended, it will truncate it. Otherwise, the sound can overlap.
    interrupt: true,
  });

  const [errorPlaySound] = useSound(errorSound, {
    volume: soundSettings.volume
      ? defaultVolumeOptionsValues[soundSettings?.volume]
      : defaultVolumeOptionsValues["medium"],

    // `interrupt` ensures that if the sound starts again before it's
    // ended, it will truncate it. Otherwise, the sound can overlap.
    interrupt: true,
  });

  // content settings var
  // Check base layer content is words or not
  const [baseLayerWords, setBaseLayerWords] = useState(true);

  // Check base layer content is quote or not
  const [baseLayerQuotes, setBaseLayerQuotes] = useState(false);

  // sub layer vars
  // numbers can be included or not
  // punctuations can be included or not
  const [includeNumbers, setIncludeNumbers] = useState(false);
  const [includePunctuations, setIncludePunctuations] = useState(false);

  // limit of words and time
  const [defaultWordsLimit, setDefaultWordsLimit] = useState(DEFAULT_WORD_10);
  const [defaultTimeLimit, setDefaultTimeLimit] = useState(DEFAULT_TIME);
  
  // initial text represents the placeholder text
  const [initialText, setInitialText] = useState("");

  // user input represents the text that user is typing
  const [userInput, setUserInput] = useState("");
  
  // state to store the test type
  // time, words, quote, numbers, punctuation
  const [testType, setTestType] = useState(null);

  const [textContentData, setTextContentData] = useState("");
  const [contentEditableFlag, setContentEditableFlag] = useState(true);

  // show time options to show or hide
  // (15, 30, 60)
  const [showTimeOptions, setShowTimeOptions] = useState(false);

  // time interval to update the time
  // from 10, 9, 8, 7, 6, 5, 4, 3, 2, to 1
  const [intervalTimer, setIntervalTimer] = useState(null);

  const [backendTimeOption, setBackendTimeOption] = useState(DEFAULT_TIME);

  // active state to show which option is active
  // (numbers, punctuation, time, words, quotes)
  const [activeItem, setActiveItem] = useState("words");

  // show side bar options to show or hide
  // (numbers, punctuation, time, words, quotes)
  const [showTestOptions, setShowTestOptions] = useState(true);

  // word limit options to show or hide
  // (10, 20, 50, 100, 200, custom word limit modal)
  const [showWordsLimits, setShowWordsLimits] = useState(true);

  // set custom word limit max is 400
  const [openWordLimitModal, setOpenWordLimitModal] = useState(false);

  // modal to show result modal after test is finished
  const [showResultModal, setShowResultModal] = useState(false);

  // custom hook which gives us the test result and handle input change function
  const { handleInputChange, testResult } = useTypingTest(initialText, textContentData);

  // context api to save test result on backend
  const {saveTestResultAPI} = useContext(TestAPIContext);

  const [showWordTimer, setShowWordTimer] = useState(true);

  const navigate = useNavigate();
  
  // Function to update the initial text eg. placeholder
  // we can add numbers, puntuation, quotes, words through this function
  const updateInitialText = () => {
    const words = wordsGenerator(
      defaultWordsLimit,
      "normal",
      "english",
      includeNumbers,
      includePunctuations,
      baseLayerQuotes,
      baseLayerWords
    );

    if (words.length > 0) {
      let wordsInitial = words
        .flat(Infinity)
        .map((item) => Object.values(item)[0])
        .join(" ");
      wordsInitial = wordsInitial.split(" ").slice(0, defaultWordsLimit).join(" ");
      setInitialText(wordsInitial);
    }
  };

  // Function to determine the test type
  // time, words, quote, numbers, punctuation
  const determineTestType = () => {
    let testTypeObj = {}

    if(showTimeOptions) {
      testTypeObj = { ...testTypeObj, time: true, seconds: defaultTimeLimit, timeLimit: backendTimeOption};
    }

    if(baseLayerWords) {
      testTypeObj = { ...testTypeObj, words: true, wordLimit: defaultWordsLimit };

    }

    if(baseLayerQuotes) {
      testTypeObj = { ...testTypeObj, quote: true };
    }

    if(includeNumbers) {
      testTypeObj = { ...testTypeObj, numbers: true };
    }

    if(includePunctuations) {
      testTypeObj = { ...testTypeObj, punctuation: true };
    }
    
    // update state for test type
    setTestType(testTypeObj);
  }

  // Function to show or hide time
  // options when time is clicked
  const enableTimer = () => {
    if (showTimeOptions) {
      setShowTimeOptions(false);
    } else {
      setShowTimeOptions(true);
    }
  };

  useEffect(() => {
    // call saveTestResultAPI
    if(showResultModal) {
      testResult["testType"] = testType;
      saveTestResultAPI(testResult);
    }
  }, [showResultModal]);

  // using useEffect to update the test type
  // when the default time limit is changed
  useEffect(() => {
    // call determineTestType
    determineTestType();
  }, [defaultTimeLimit]);

  useEffect(() => {
    updateInitialText();
    determineTestType();
  }, [defaultWordsLimit, 
      activeItem, includeNumbers, 
      includePunctuations, 
      baseLayerQuotes, 
      baseLayerWords]);
  

  // Function to handle the content change
  // when user types the content
  const handleContentChange = (event) => {
    const newContent = event.target.textContent;
    handleInputChange();
    setTextContentData(newContent);
    setUserInput(newContent);
    if (initialText.length === newContent.length) {
        setContentEditableFlag(false);
        setShowResultModal(true);
    }
  };

  const generateColoredText = () => {
    let correctHtml = "";
    let incorrectHtml = "";

    for (let i = 0; i < userInput.length; i++) {
      if (userInput[i] === initialText[i]) {
        correctHtml += `<span style="color: green;">${userInput[i]}</span>`;
      } else {
        incorrectHtml += `<span style="color: red;">${userInput[i]}</span>`;
      }
    }

    return correctHtml + incorrectHtml;
  };

  const handleTimeClick = () => {
    if (showWordTimer) {
      setShowWordTimer(false);
    }
    
    setShowTimeOptions(true);
    setActiveItem("time");
  }


  const handleAddWordClick = () => {
    if(showTimeOptions) {
      setShowTimeOptions(false);
    }

    setShowWordTimer(true);
    setBaseLayerWords(true);
    setBaseLayerQuotes(false);
    setShowWordsLimits(true);
    setActiveItem("words");
  }

  const handleQuoteClick = () => {
    if (baseLayerQuotes) {
      setBaseLayerQuotes(false);
      setBaseLayerWords(true);
      setShowWordsLimits(true);
      setActiveItem("words");
    } else {
      setBaseLayerQuotes(true)
      setBaseLayerWords(false);
      setShowWordsLimits(false);
      setActiveItem("quotes");
    }
  }

  // Function to initialize a timer of default 10 seconds
  const initializeTestTimer = () => {
    setBackendTimeOption(defaultTimeLimit);
    let timer = defaultTimeLimit;
    let interval = setInterval(() => {
      setDefaultTimeLimit(timer);
      setIntervalTimer(interval);
      timer--;
      if (timer === 0) {
        clearInterval(interval);
        setDefaultTimeLimit(timer); // Set time to 0 after clearing the interval
        setIntervalTimer(null);
        setShowResultModal(true);
      }
    }, 1000);
  };

  // update the words limit and re populate the initial text
  const updateWordsLimit = (wordsLimit) => {
    setDefaultWordsLimit(wordsLimit);
  }

  const handlePlaySound = function (e) {
    if (testResult && testResult.incorrectCharacters > 0 && soundSettings.errorSound) {
      errorPlaySound();
    } else if (testResult && soundSettings.typeSound) {
      playSound();
    }
  }

  const restartTest = () => {
    console.log("Navigating to /practice");

    window.location.href = "/practice";
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === 'r') {
        e.preventDefault();
        restartTest();
      }

      if (e.ctrlKey && e.key === 'i') {
        e.preventDefault();
        toggleInstructions();
      }

      if (e.key === 'Escape') {
        e.preventDefault();
        escapeHandler();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const toggleInstructions = () => {
    console.log("Toggling instructions");
    setShowTestOptions(prev => !prev);
  };

  const escapeHandler = () => {
      navigate("/");
  };

  useEffect(() => {
    const shortcuts = [
      "Ctrl + R: Restart the test",
      "Ctrl + I: Show or hide test options",
      "ESC: Go back to the home page"
    ];

    const icons = ["🚀", "✨", "🔥", "💡", "🎉"];
    let lastShortcutIndex = -1;
  
    const showRandomShortcut = () => {
      if (textContentData) {
        return;
      }
  
      console.log("Showing random shortcut");
      let randomIndex;
      do {
        randomIndex = Math.floor(Math.random() * shortcuts.length);
      } while (randomIndex === lastShortcutIndex);
  
      lastShortcutIndex = randomIndex;
  
      returnToast(shortcuts[randomIndex], {
        position: "top-right",
        autoClose: 5000,
        icon: icons[randomIndex % icons.length], // Ensure icon index is within bounds
      });
    };

    // Show a random shortcut immediately when the page reloads
    showRandomShortcut();

    // Continue to show random shortcuts at intervals
    const intervalId = setInterval(showRandomShortcut, 10000); // Show a random shortcut every 5 seconds
  
    return () => clearInterval(intervalId);
  }, [textContentData]);

  return (
    <section style={{ backgroundColor: theme.bgColor }}>
      <div className="container mx-auto">
        <div className="flex flex-wrap items-center h-screen text-white">
          {/* <!-- Sidebar --> */}

          <div
            style={{ backgroundColor: showTestOptions ? theme.mainColor : "" }}
            className={`w-[40px] mr-[90px] rounded-lg shadow p-[10px]`}
          >
            {/* Go Back link in the upper right corner */}
            {showTestOptions && (
              <>
                <div className="absolute top-4 right-4">
                  <Link
                    to="/"
                    className={`text-[${theme.subColor}] text-[20px] font-bold underline`}
                  >
                    Go Back
                  </Link>
                </div>
                {/* <!-- Sidebar Content --> */}
                <motion.ul 
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 1.5 }}
                transition={{ duration: 0.5 }}
                className="mt-[10px] text-center">
                  <li className="mt-[10px] cursor-pointer">
                    <div
                      onClick={() => {
                        setIncludeNumbers(!includeNumbers);
                        setActiveItem("numbers");
                      }}
                      style={{ color: theme.subColor }}
                      className={`group relative text-[15px] font-bold hover:text-[#d7fec8] ${
                        activeItem === "numbers" ? "active" : ""
                      }`}
                    >
                      <i className="fa-solid fa-hashtag"></i>{" "}
                      <span className="tooltip bg-gray-800 text-white p-1 rounded text-base">
                        numbers
                      </span>
                    </div>
                  </li>
                  <li className="mt-[10px] cursor-pointer">
                    <div
                      onClick={() => {
                        setIncludePunctuations(!includePunctuations);
                        setActiveItem("punc");
                      }}
                      style={{ color: theme.subColor }}
                      className={`group relative text-[15px] font-bold hover:text-[#d7fec8] ${
                        activeItem === "punc" ? "active" : ""
                      }`}
                    >
                      <i className="fa-solid fa-exclamation"></i>{" "}
                      <span className="tooltip bg-gray-800 text-white p-1 rounded text-base">
                        punctuation
                      </span>
                    </div>
                  </li>
                  <hr className="mt-[15px]" style={{ color: theme.subColor }} />
                  <li className="mt-[10px] cursor-pointer">
                    <div
                      onClick={handleTimeClick}
                      className={`group relative text-[15px] font-bold hover:text-[#d7fec8] ${
                        activeItem === "time" ? "active" : ""
                      }`}
                      style={{ color: theme.subColor }}
                    >
                      <i className="fa-solid fa-clock"></i>
                      <span className="tooltip bg-gray-800 text-white p-1 rounded text-base">
                        time
                      </span>
                    </div>
                  </li>
                  <li className="mt-[10px] cursor-pointer">
                    <div
                      onClick={() => {
                        handleAddWordClick();
                      }}
                      style={{ color: theme.subColor }}
                      className={`group relative text-[15px] font-bold hover:text-[#d7fec8] ${
                        activeItem === "words" ? "active" : ""
                      }`}
                    >
                      <i className="fa-solid fa-font"></i>{" "}
                      <span className="tooltip bg-gray-800 text-white p-1 rounded text-base">
                        words
                      </span>
                    </div>
                  </li>
                  <li className="mt-[10px] mb-[10px] cursor-pointer">
                    <div
                      onClick={handleQuoteClick}
                      style={{ color: theme.subColor }}
                      className={`group relative text-[15px] font-bold hover:text-[#d7fec8] ${
                        activeItem === "quotes" ? "active" : ""
                      }`}
                    >
                      <i className="fa-solid fa-quote-right"></i>
                      <span className="tooltip bg-gray-800 text-white p-1 rounded text-base">
                        quotes
                      </span>
                    </div>
                  </li>
                  {showTimeOptions ? (
                    <>
                      <hr
                        className="mt-[15px]"
                        style={{ color: theme.subColor }}
                      />
                      <li
                        className="mt-[12px] cursor-pointer"
                        style={{ color: theme.subColor }}
                        onClick={() => {
                          setDefaultTimeLimit(DEFAULT_TIME_15);
                        }}
                      >
                        <a
                          href="#"
                          className="text-[12px] font-bold hover:text-[#d7fec8]"
                        >
                          15
                        </a>
                      </li>
                      <li
                        className="mt-[12px] cursor-pointer"
                        style={{ color: theme.subColor }}
                        onClick={() => {
                          setDefaultTimeLimit(DEFAULT_TIME_30);
                        }}
                      >
                        <a
                          href="#"
                          className="text-[12px] font-bold hover:text-[#d7fec8]"
                        >
                          30
                        </a>
                      </li>
                      <li
                        className="mt-[12px] cursor-pointer"
                        style={{ color: theme.subColor }}
                        onClick={() => {
                          setDefaultTimeLimit(DEFAULT_TIME_60);
                        }}
                      >
                        <a
                          href="#"
                          className="text-[12px] font-bold hover:text-[#d7fec8]"
                        >
                          60
                        </a>
                      </li>
                    </>
                  ) : null}

                  {showWordsLimits ? (
                    <>
                      <hr
                        className="mt-[15px]"
                        style={{ color: theme.subColor }}
                      />
                      <li
                        className="mt-[12px] cursor-pointer"
                        style={{ color: theme.subColor }}
                        onClick={() => updateWordsLimit(DEFAULT_WORD_10)}
                      >
                        <a
                          href="#"
                          className="text-[12px] font-bold hover:text-[#d7fec8]"
                        >
                          10
                        </a>
                      </li>
                      <li
                        className="mt-[12px] cursor-pointer"
                        style={{ color: theme.subColor }}
                        onClick={() => updateWordsLimit(DEFAULT_WORD_20)}
                      >
                        <a
                          href="#"
                          className="text-[12px] font-bold hover:text-[#d7fec8]"
                        >
                          20
                        </a>
                      </li>
                      <li
                        className="mt-[12px] cursor-pointer"
                        style={{ color: theme.subColor }}
                        onClick={() => updateWordsLimit(DEFAULT_WORD_50)}
                      >
                        <a
                          href="#"
                          className="text-[12px] font-bold hover:text-[#d7fec8]"
                        >
                          50
                        </a>
                      </li>
                      <li
                        className="mt-[12px] cursor-pointer"
                        style={{ color: theme.subColor }}
                        onClick={() => updateWordsLimit(DEFAULT_WORD_100)}
                      >
                        <a
                          href="#"
                          className="text-[12px] font-bold hover:text-[#d7fec8]"
                        >
                          100
                        </a>
                      </li>
                      <li
                        className="mt-[12px] cursor-pointer"
                        style={{ color: theme.subColor }}
                        onClick={() => updateWordsLimit(DEFAULT_WORD_200)}
                      >
                        <a
                          href="#"
                          className="text-[12px] font-bold hover:text-[#d7fec8]"
                        >
                          200
                        </a>
                      </li>
                      <li
                        className="mt-[12px] cursor-pointer"
                        style={{ color: theme.subColor }}
                        onClick={() => setOpenWordLimitModal(true)}
                      >
                        <a
                          href="#"
                          className="group relative text-[15px] font-bold hover:text-[#d7fec8]"
                        >
                          <i className="fa-solid fa-wrench"></i>
                        </a>
                      </li>
                    </>
                  ) : null}
                </motion.ul>
              </>
            )}
          </div>

          {/* <!-- Dashboard Content --> */}
          <motion.div 
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          transition={{ duration: 0.5 }}
          className="flex-1 p-4 highlight-cursor">
            <div>
              <label
                style={{ color: theme.mainColor }}
                htmlFor="dashboard-content"
                className="text-xl capitalize font-bold mb-4 block"
              >
                English
              </label>
              {showTimeOptions ? (
                <Timer theme={theme} defaultTime={defaultTimeLimit} />
              ) : null}
               {showWordTimer ? (
                <WordLimit theme={theme} wordsLimit={testResult.differences.length} />
              ) : null}
              <div
                className="outer-container"
                onClick={() => {
                  if (showTimeOptions) {
                    initializeTestTimer();
                  }
                  setShowTestOptions(false);
                }}
                style={{ color: "grey" }}
              >
                <div
                  className="editable-div"
                  spellCheck={true}
                  onKeyDown={handlePlaySound}
                  onInput={(e) => {
                    e.preventDefault();
                    handleContentChange(e);
                  }}
                  contentEditable={contentEditableFlag}
                  // dangerouslySetInnerHTML={{ __html: generateColoredText() }}
                  style={{
                    border: "none",
                    outline: "none",
                    color: theme.mainColor,
                    cursor: "text",
                  }} // Add border and outline: "none" here
                />
                <div style={{cursor:"text"}}>
                  {/* <!-- Other content goes here --> */}
                  {initialText}
                </div>
              </div>
            </div>
          </motion.div>
        </div>
        <WordLimitModal theme={theme} updateWordsLimit={updateWordsLimit} isOpen={openWordLimitModal} onClose={setOpenWordLimitModal} />
        {
          showResultModal && (
            <ResultModal testType={testType} showResultModal={showResultModal} theme={theme} setShowResultModal={setShowResultModal} result={testResult}/>
          )
        }
      </div>
    </section>
  );
}

export default TypingSection;
