import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const TypingPerformanceChart = ({data}) => {
  const  getTodayDateWithMonth = () => {
    const date = new Date();
    return date.toLocaleString('default', { day: 'numeric', month: 'short' });
  }

  const [chartOptions, setChartOptions] = useState({
    series: [
      {
        name: "WPM",
        data: [data.wpm]
      },
      {
        name: "Accuracy",
        data: [data.accuracy]
      },
      {
        name: 'Corrected Words',
        data: [data.correctChars]
      }
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [5, 7, 5],
        curve: 'straight',
        dashArray: [0, 8, 5]
      },
      title: {
        text: 'Test Statistics',
        align: 'left',
        style: {
          fontSize: "16px",
          color: '#666',
          fontFamily: 'Roboto'
        }
      },
      legend: {
        tooltipHoverFormatter: function(val, opts) {
          return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
        }
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6
        }
      },
      xaxis: {
        categories: [getTodayDateWithMonth(),
        ],
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val + " (mins)"
              }
            }
          },
          {
            title: {
              formatter: function (val) {
                return val + " per session"
              }
            }
          },
          {
            title: {
              formatter: function (val) {
                return val;
              }
            }
          }
        ]
      },
      grid: {
        borderColor: '#f1f1f1',
      }
    },
    
  });

  return (
    <div className='w-full mx-auto relative overflow-auto'>
      <div id="chart" className='w-11/12 mx-auto h-fit '>
        <ReactApexChart  options={chartOptions.options} series={chartOptions.series} type="line" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default TypingPerformanceChart;