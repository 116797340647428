import { motion } from "framer-motion";
import React from "react";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import { useTheme } from "../context/themeContext";
import CertificateBenefits from "../sections/CertificateSections/CertificateBenefits";
import CertificateHeroSection from "../sections/CertificateSections/CertificateHeroSection";
import CertificateInfo from "../sections/CertificateSections/CertificateInfo";
import CertificateWhy from "../sections/CertificateSections/CertificateWhy";

export default function CertificatePage() {
  const { theme} = useTheme();
  return (
    <motion.div>
    <Navbar />
        <CertificateHeroSection theme={theme} />
        <CertificateInfo theme={theme} />
        <CertificateWhy theme={theme}/>
        <CertificateBenefits theme={theme}/>
    <Footer />
    </motion.div>
  );
}
