import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";


function CtaSection({theme}) {
  
  return (
    <section style={{ backgroundColor: theme.bgColor }} className="bg-[#006a43] dark:bg-gray-900">
      <div className="banner-tab banner-tab2 grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div className="banner-left-tab lg:mt-0 md:col-span-5 lg:col-span-5 lg:flex">
          <motion.img
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          whileHover={{ scale: 1.1, transition: { duration: 0.2 } }}
          whileTap={{ scale: 0.9, transition: { duration: 0.2 } }}
          transition={{ duration: 1 }}
            className="w-[80%] object-[contain] rounded-[20px] cursor-pointer"
            src="https://img.freepik.com/free-vector/demo-concept-illustration_114360-7612.jpg?w=740&t=st=1693493031~exp=1693493631~hmac=4f9ff440f1825c20d1b2c86b74ee582ae0064b55f2cc092653417f5dfa98fe22"
            alt="mockup"
          />
        </div>
        <div className="banner-left-tab mr-auto place-self-center md:col-span-7 lg:col-span-7">
          <span style={{color: theme.themeName == "default" ? theme.subAltColor : theme.textColor}} className="max-w-2xl mb-6 font-light lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
            News
          </span>
          <h2 style={{ color: theme.mainColor }} className="max-w-2xl mb-4 mt-4 text-3xl text-[#fff] font-extrabold tracking-tight leading-none md:text-3xl xl:text-4xl dark:text-white">
            CrazyTypist, Your Path to Typing Mastery
          </h2>
          <p style={{color: theme.themeName == "default" ? theme.subAltColor : theme.textColor}} className="max-w-2xl mb-6 font-light lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
            Unleash Your Typing Superpowers with HERO Typing: Master the
            Keyboard, Boost Your Speed, and Conquer the Digital World!
          </p>
          <Link
            style={{ backgroundColor: theme.mainColor , color: theme.subAltColor }}
            to="/practice"
            className="custom-btn-mob inline-flex bg-[#000] items-center justify-center px-5 py-3 text-base font-medium text-center text-white border border-[#000] rounded-lg hover:bg-white hover:text-[#0b996f] hover:border-[#fff] focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
          >
            Let's Unleash
          </Link>
        </div>
      </div>
    </section>
  );
}

export default CtaSection;
