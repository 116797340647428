import { motion } from "framer-motion";
import React from "react";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import MainSection from "../sections/ResetPasswordSections/MainSection";

function ForgetPassowordPage({theme}) {
  return (
    <>
      <motion.main
        initial={{ width: 0, transition: { duration: 0.05 } }}
        animate={{ width: "100%" }}
        exit={{ width: window.innerWidth, transition: { duration: 0.05 } }}
        smooth={true}
      >
        <Navbar />
        <MainSection theme={theme} />
        <Footer />
      </motion.main>
    </>
  );
}

export default ForgetPassowordPage;
