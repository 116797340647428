import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import serverURL from "../../serverConfig";
import { returnToast } from "../utils/helper";
import AuthContext from "./AuthContext";

const TestAPIContext = createContext();

export default TestAPIContext;

export const TestAPIProvider = ({ children }) => {
  const navigate = useNavigate();
  let { user, authTokens } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);

  const saveTestResultAPI = async (testResult) => {
    if (!authTokens) {
      returnToast("Please login to save your test result.", {
        className: "bg-red-500",
      });

      return;
    }

    setLoading(true);
    let url = `${serverURL}/api/typing-test/create-result/`

    try {
      const response = await fetch(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authTokens.access,
          },
          body: JSON.stringify({
            test_type: testResult.testType,
            accuracy: testResult.accuracy.toFixed(2),
            wpm: testResult.typingSpeed.toFixed(2),
            consistency: testResult.consistency,
            time: testResult.typingSpeed.toFixed(2),
            character_count: testResult.correctCharacters,
            user: user.user_id,
          }),
        }
      );
      const data = await response.json();

      if (response.status === 201) {
        return returnToast("Test result saved successfully.", {
          className: "bg-green-500",
        });
      } else if (response.status === 401) {
        returnToast(
          "Invalid credentials. or your account might be inactive/deleted."
        );
      } else if (response.status === 500) {
        returnToast("Something went wrong. Please try again.");
      }
    } catch (error) {
      returnToast(error.message, { className: "bg-red-500" });
    } finally {
      setLoading(false);
    }
  };

  const getTypingResultAPI = async () => {
    setLoading(true);
    try {
      let url = `${serverURL}/api/profiles/test-result-count/${user.user_id}/`;
      const response = await fetch(
        url,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authTokens.access,
          },
        }
      );
      const data = await response.json();
      if (response.status === 401) {
        returnToast(
          "Invalid credentials. or your account might be inactive/deleted."
        );
      } else if (response.status === 500) {
        returnToast("Something went wrong. Please try again.");
      }
    } catch (error) {
      returnToast(error.message, { className: "bg-red-500" });
    } finally {
      setLoading(false);
    }
  };

  const getCertificateTypeResultAPI = async (testResult) => {
    setLoading(true);
    let result;

    try {
      let url = `${serverURL}/api/typing-test/certificate-stat/`;
      const headers = {
        "Content-Type": "application/json",
      };

      if (authTokens && authTokens.access) {
        headers.Authorization = "Bearer " + authTokens.access;
      }

      const response = await fetch(
        url,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            typing_speed: testResult.typingSpeed.toFixed(2),
            accuracy: testResult.accuracy.toFixed(2),
          }),
        }
      );

      let data = await response.json();
      result = { status: response.status, data: data};

      if (response.status === 401) {
        returnToast(
          "Invalid credentials. or your account might be inactive/deleted."
        );
      } else if (response.status === 500) {
        returnToast("Something went wrong. Please try again.");
      }
    } catch (error) {
      returnToast(error.message, { className: "bg-red-500" });
    } finally {
      setLoading(false);
    }
    return result;
  };

  // Get user all certificate
  const getAllCertificates = async () => {
    setLoading(true);
    try {
      let url = `${serverURL}/api/profiles/get-certificates/${user.user_id}/`;
      const response = await fetch(
        url,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authTokens.access,
          },
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        return data;
      } else if (response.status === 401) {
        returnToast(
          "Invalid credentials. or your account might be inactive/deleted."
        );
      } else if (response.status === 500) {
        returnToast("Something went wrong. Please try again.");
      }
    } catch (error) {
      returnToast(error.message, { className: "bg-red-500" });
    } finally {
      setLoading(false);
    }
  };

  const getLeaderBoardData = async (preference) => {
    setLoading(true);
    try {
      let url = `${serverURL}/api/typing-test/leaderboard/?time=${preference.time}&allTime=${preference.allTime}&today=${preference.today}`;
      const response = await fetch(
        url,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      
      if (response.status === 200) {
        return data;
      }
    } catch (error) {
      returnToast(error.message, { className: "bg-red-500" });
    } finally {
      setLoading(false);
    }
  };

  const contextData = {
    loading: loading,
    saveTestResultAPI: saveTestResultAPI,
    getTypingResultAPI: getTypingResultAPI,
    getCertificateTypeResultAPI: getCertificateTypeResultAPI,
    getAllCertificates: getAllCertificates,
    getLeaderBoardData: getLeaderBoardData,
  };

  return (
    <TestAPIContext.Provider value={contextData}>
      {children}
    </TestAPIContext.Provider>
  );
};
