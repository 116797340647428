import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import CertificateImg from "../../assets/images/certificate.png";
import Feature1Img from "../../assets/images/feature1.png";
import GamesImg from "../../assets/images/games.png";
import LeaderboardImg from "../../assets/images/leaderboard.png";

function FeatureSection() {
  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div className="card-title m-auto place-self-center lg:col-span-12">
          <h2 className="m-auto text-center text-[#00000] max-w-2xl mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl xl:text-4xl dark:text-white">
            CrazyTypist Features
          </h2>
          <p className="m-auto text-center max-w-2xl mt-6 mb-6 font-[400] text-[#474747] lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
           Unlock your typing potential with CrazyTypist's innovative features.
          </p>
        </div>
        <div className="flex items-center flex-wrap justify-center gap-[3%]">
          <div className="w-full md:w-[45%] mb-4 md:mb-[3%]">
            <Link
              to="/practice"
              className="custom-card flex flex-wrap md:flex-nowrap content-between justify-between items-center p-6 bg-[#dbd9f7] border-3 border-[#dbd9f7] rounded-[15px] shadow dark:bg-gray-800 cursor-pointer dark:border-gray-700 w-full"
            >
              <div className="w-[30%] md:w-auto inline-block mb-4 md:mb-0">
                <motion.img
                  initial={{ scale: 0 }}
                  animate={{ scale: 1}}
                  whileHover={{ scale: 1.8 }}
                  src={Feature1Img}
                  alt="Image"
                  className="w-full h-auto mr-4"
                />
              </div>
              <div className="w-[65%] ml-[10px] md:w-auto inline-block">
                <h5 className="text-xl font-medium block">Practice Typing</h5>
                <span className="text-[13px] font-medium block">
                  Master the Keyboard
                </span>
                <span className="mb-3 mt-3 text-sm block font-normal text-gray-500 dark:text-gray-400">
                  Refine your typing skills with our tailored exercises and
                  challenges. Whether you're a beginner or a pro, CrazyTypist
                  provides the perfect platform to improve your typing speed and
                  accuracy.
                </span>
                <div className="block">
                  <span className="text-lg font-medium">Find out more</span>
                  <svg
                    fill="none"
                    height="16"
                    viewBox="0 0 24 22"
                    width="16"
                    className="inline-block ml-2"
                  >
                    <path
                      d="M23.6833 11C18.3838 11 14.0972 15.8855 14.0972 21.9253M23.5861 11C18.2866 11 14 6.11457 14 0.074707M24 11H0"
                      stroke="currentColor"
                      strokeWidth="2"
                    ></path>
                  </svg>
                </div>
              </div>
            </Link>
          </div>
          <div className="w-full mb-4 md:w-[45%] mb-4 md:mb-[3%]">
            <Link
              to="/get-certificate"
              className="custom-card custom-card1 flex flex-wrap md:flex-nowrap content-between justify-between items-center p-6 bg-[#ffe7e0] border-3 border-[#ffe7e0] rounded-[15px] shadow dark:bg-gray-800 cursor-pointer dark:border-gray-700 w-full"
            >
              <div className="w-[50%] md:w-auto inline-block mb-4 md:mb-0">
                <motion.img
                initial={{ scale: 0 }}
                animate={{ scale: 1}}
                whileHover={{ scale: 1.5 }}
                  src={CertificateImg}
                  alt="Image"
                  className="w-full h-auto mr-4"
                />
              </div>
              <div className="w-[65%] ml-[10px] md:w-auto inline-block">
                <h5 className="text-xl font-medium block">Earn Certificates</h5>
                <span className="text-[13px] font-medium block">
                  Certify Your Typing Proficiency
                </span>
                <span className="mb-3 mt-3 text-sm block font-normal text-gray-500 dark:text-gray-400">
                  Showcase your typing prowess with our certification program.
                  Earn recognition for your dedication and receive official
                  certificates that validate your expertise. Become a certified
                  typing champion!
                </span>
                <div className="block">
                  <span className="text-lg font-medium">Find out more</span>
                  <svg
                    fill="none"
                    height="16"
                    viewBox="0 0 24 22"
                    width="16"
                    className="inline-block ml-2"
                  >
                    <path
                      d="M23.6833 11C18.3838 11 14.0972 15.8855 14.0972 21.9253M23.5861 11C18.2866 11 14 6.11457 14 0.074707M24 11H0"
                      stroke="currentColor"
                      strokeWidth="2"
                    ></path>
                  </svg>
                </div>
              </div>
            </Link>
          </div>
          <div className="w-full md:w-[45%] mb-4 md:mb-0">
            <Link
              to="/leaderboard"
              className="custom-card custom-card2 flex flex-wrap md:flex-nowrap content-between justify-between items-center p-6 bg-[#fce2eb] border-3 border-[#fce2eb] rounded-[15px] shadow dark:bg-gray-800 cursor-pointer dark:border-gray-700 w-full"
            >
              <div className="w-[30%] md:w-auto inline-block mb-4 md:mb-0">
                <motion.img
                 initial={{ scale: 0 }}
                 animate={{ scale: 1}}
                 whileHover={{ scale: 1.8 }}
                  src={LeaderboardImg}
                  alt="Image"
                  className="w-full h-auto mr-4"
                />
              </div>
              <div className="w-[65%] ml-[10px] md:w-auto inline-block">
                <h5 className="text-xl font-medium block">Get into Leaderboard</h5>
                <span className="text-[13px] font-medium block">
                  Climb the Typing Ranks
                </span>
                <span className="mb-3 mt-3 text-sm block font-normal text-gray-500 dark:text-gray-400">
                  Compete with typists from around the world and see how you
                  stack up on our global leaderboard. Test your skills, track
                  your progress, and strive to reach the top. Are you ready for
                  the challenge?
                </span>
                <div className="block">
                  <span className="text-lg font-medium">Find out more</span>
                  <svg
                    fill="none"
                    height="16"
                    viewBox="0 0 24 22"
                    width="16"
                    className="inline-block ml-2"
                  >
                    <path
                      d="M23.6833 11C18.3838 11 14.0972 15.8855 14.0972 21.9253M23.5861 11C18.2866 11 14 6.11457 14 0.074707M24 11H0"
                      stroke="currentColor"
                      strokeWidth="2"
                    ></path>
                  </svg>
                </div>
              </div>
            </Link>
          </div>
          <div className="w-full md:w-[45%] md:mb-0">
            <Link
              to="/games"
              className="custom-card custom-card3 flex flex-wrap md:flex-nowrap content-between justify-between items-center p-6 bg-[#dbf4f4] border-3 border-[#dbf4f4] rounded-[15px] shadow dark:bg-gray-800 cursor-pointer dark:border-gray-700 w-full"
            >
              <div className="w-[30%] md:w-auto inline-block mb-4 md:mb-0">
                <motion.img
                initial={{ scale: 0 }}
                animate={{ scale: 1}}
                whileHover={{ scale: 2 }}
                src={GamesImg} alt="Image" className="w-full h-auto  mr-4" />
              </div>
              <div className="w-[65%] ml-[10px] md:w-auto inline-block">
                <h5 className="text-xl font-medium block">
                  Practice through Play Games
                </h5>
                <span className="text-[13px] font-medium block">
                  Fun Learning, Fast Typing
                </span>
                <span className="mb-3 mt-3 text-sm block font-normal text-gray-500 dark:text-gray-400">
                  Experience the joy of learning through play! Explore our
                  collection of typing games designed to make practice
                  enjoyable. Enhance your typing skills while having a blast
                  with CrazyTypist's interactive games
                </span>
                <div className="block">
                  <span className="text-lg font-medium">Find out more</span>
                  <svg
                    fill="none"
                    height="16"
                    viewBox="0 0 24 22"
                    width="16"
                    className="inline-block ml-2"
                  >
                    <path
                      d="M23.6833 11C18.3838 11 14.0972 15.8855 14.0972 21.9253M23.5861 11C18.2866 11 14 6.11457 14 0.074707M24 11H0"
                      stroke="currentColor"
                      strokeWidth="2"
                    ></path>
                  </svg>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureSection;
