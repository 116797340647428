import React from 'react'

function WordLimit({theme, wordsLimit}) {
  return (
    <label
      htmlFor="dashboard-content"
      style={{color: theme.mainColor}}
      className="text-medium capitalize font-bold mb-4 block"
    >
      Words: {wordsLimit}
    </label>
  )
}

export default WordLimit
