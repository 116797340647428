import React, { Suspense } from "react";
const Marquee = React.lazy(() => import("react-fast-marquee"));

function AwardSection() {
  return (
    <section className="bg-[#fffdf6] dark:bg-gray-900">
      <div className="max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-[100px] lg:grid-cols-12">
        <div className="m-auto place-self-center lg:col-span-12">
          <h2 className="m-auto text-center max-w-2xl mb-4 text-3xl text-[#6358de] font-bold tracking-tight leading-none md:text-4xl xl:text-4xl dark:text-white">
            Awards & Recognition  
          </h2>
          <p className="m-auto text-center max-w-2xl mb-6 font-[400] lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
            We are proud to be recognized by the following organizations for our
            commitment to delivering the best possible experience for our
            students.
          </p>
        </div>
        
        <div className="mob-center flex mt-[40px] items-center flex-wrap gap-18 justify-between">
          <Suspense fallback={<div>Loading...</div>}>
            <Marquee pauseOnHover={true} className="cursor-pointer" gradientColor={[99, 88, 222]} gradient={200}>
              <img
                alt="awardlogo-0"
                loading="lazy"
                width="130"
                height="96"
                decoding="async"
                data-nimg="1"
                className="ml-[4rem]"
                style={{ color: "transparent" }}
                src="https://corp-backend.brevo.com/wp-content/uploads/2023/03/G2LeaderSmallBusiness.svg"
              />
              <img
                alt="awardlogo-1"
                loading="lazy"
                width="130"
                height="96"
                decoding="async"
                data-nimg="1"
                className="ml-[4rem]"
                style={{ color: "transparent" }}
                src="https://corp-backend.brevo.com/wp-content/uploads/2023/03/G2BestUsability.svg"
              />
              <img
                alt="awardlogo-2"
                loading="lazy"
                width="130"
                height="96"
                decoding="async"
                data-nimg="1"
                className="ml-[4rem]"
                style={{ color: "transparent" }}
                src="https://corp-backend.brevo.com/wp-content/uploads/2023/03/G2LeaderEurope.svg"
              />
              <img
                alt="awardlogo-3"
                loading="lazy"
                width="130"
                height="96"
                decoding="async"
                data-nimg="1"
                className="ml-[4rem]"
                style={{ color: "transparent" }}
                src="https://corp-backend.brevo.com/wp-content/uploads/2023/03/G2MidMarket.svg"
              />
              <img
                alt="awardlogo-4"
                loading="lazy"
                width="130"
                height="96"
                decoding="async"
                data-nimg="1"
                className="ml-[4rem]"
                style={{ color: "transparent" }}
                src="https://corp-backend.brevo.com/wp-content/uploads/2023/03/Martech.svg"
              />
              <img
                alt="awardlogo-5"
                loading="lazy"
                width="130"
                height="96"
                decoding="async"
                data-nimg="1"
                className="ml-[4rem]"
                style={{ color: "transparent" }}
                src="https://corp-backend.brevo.com/wp-content/uploads/2023/03/TrustRadius.svg"
              />
              <img
                alt="awardlogo-6"
                loading="lazy"
                width="130"
                height="96"
                decoding="async"
                data-nimg="1"
                className="ml-[4rem]"
                style={{ color: "transparent" }}
                src="https://corp-backend.brevo.com/wp-content/uploads/2023/03/Stevie-Awards.svg"
              />
            </Marquee>
          </Suspense>
        </div>
      </div>
    </section>
  );
}

export default AwardSection;