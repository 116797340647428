
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import wordsGenerator from "../../assets/scripts/wordGenerator";
import TestAPIContext from "../../context/TestContext";
import { useTypingTest } from "../PracticeDashboardSections/TypistCustomHook";
import CertificateTestOptions from "./CertificateTestOptions";


export default function TypingTestSection({ theme }) {
  const [initialText, setInitialText] = useState("");
  const [words, setWords] = useState([]);
  
  const [defaultWordsLimit, setDefaultWordsLimit] = useState(10);
  
  const [includeNumbers, setIncludeNumbers] = useState(false);
  const [includePunctuations, setIncludePunctuations] = useState(false);
  const [baseLayerQuotes, setBaseLayerQuotes] = useState(false);
  const [baseLayerWords, setBaseLayerWords] = useState(false);
  const [textContentData, setTextContentData] = useState("");
  const [backendTestResult, setBackendTestResult] = useState({});

  // user input represents the text that user is typing
  const [userInput, setUserInput] = useState("");

  const { handleInputChange, testResult } = useTypingTest(initialText, textContentData);
  
  const { getCertificateTypeResultAPI } = useContext(TestAPIContext);

  let navigate = useNavigate();

  useEffect(() => {
    updateInitialText();
  }, [
    defaultWordsLimit,
    includeNumbers,
    includePunctuations,
    baseLayerQuotes,
    baseLayerWords,
  ]);


  const updateInitialText = () => {
    const words = wordsGenerator(
      defaultWordsLimit,
      "normal",
      "english",
      includeNumbers,
      includePunctuations,
      baseLayerQuotes,
      baseLayerWords
    );

    if (words.length > 0) {
      let wordsInitial = words
        .flat(Infinity)
        .map((item) => Object.values(item)[0])
        .join(" ");
      wordsInitial = wordsInitial.split(" ").slice(0, defaultWordsLimit).join(" ");
      setInitialText(wordsInitial);
    }
  };
  
  const fetchData = async () => {
    const result = await getCertificateTypeResultAPI(testResult);

    if (result.status === 200) {
      setBackendTestResult(result.data);

      if (result.data.stats.certificate_type) {
        navigate("/certificate-result", { state: result.data });
      }
    }
  };



  // Function to handle the content change
  // when user types the content
  const handleContentChange = (event) => {
    const newContent = event.target.value;
    
    handleInputChange();
    setTextContentData(newContent);
    setUserInput(newContent);
    if (initialText.length === newContent.length) {
      fetchData();
    }
  };


  return (
    <section
      style={{ backgroundColor: theme.bgColor }}
      className="bg-[#fff] dark:bg-gray-900"
    >
      <div className="banner-tab banner-tab1 grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div className="m-auto text-center place-self-center lg:col-span-12">
          <h1
            style={{ color: theme.mainColor }}
            className="max-w-4xl mb-4 text-4xl text-[#0b996f] font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white"
          >
            Certificate Typing Test
          </h1>
          <div className="text-right mb-4">
           <CertificateTestOptions setDefaultWordsLimit={setDefaultWordsLimit} />
          </div> 

          <div className="">
          <div
            id="dashboard-content"
            className={`text-left text-[18px] bg-white bg-opacity-50 leading-[45px] text-[${theme.mainColor}] w-[875px] min-h-[210px] p-[35px] rounded-lg focus:ring-2 focus:ring-[#006a43] focus:border-white`}
          >
            {initialText}
          </div>
          <h3 className="text-white py-5 text-left"></h3>
          <textarea
            id="dashboard-content"
            onInput={(e) => {
              e.preventDefault();
              handleContentChange(e);
              e.target.style.height = 'auto';
              e.target.style.height = `${e.target.scrollHeight}px`;
            }}
            className={`text-[18px] bg-white bg-opacity-50 leading-[45px] resize-none text-[${theme.mainColor}] w-[875px] min-h-[210px] p-[35px] border border-[${theme.bgColor}] rounded-lg`}
            placeholder="Start typing here..."
          ></textarea>
          </div>
        </div>
      </div>
    </section>
  );
}
